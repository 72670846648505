import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../App";
import Axios from "axios";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import AddNewUserForm from "./addUserForm";
import { GetUsersList } from "./usersList";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "../../navigation/index.css";
import "../../App.css";

const AddNewUser = () => {
    const [usersList, setUsersList] = useState([]);
    const [errors, setErrors] = useState({});
    const [count, setCount] = useState(0);
    const [hotelsList, setHotelsList] = useState([]);
    const [departamentsList, setDepartamentsList] = useState([]);

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const userData = JSON.parse(localStorage.getItem("user"));

    const { user } = useContext(UserContext);
    if (!user) {
        // Redirect to login if no user
        window.location.href = "/login";
        return null;
    }

    const handleState = () => {
        setCount(count + 1);
    };

    const getUsersListByUserId = async () => {
        const response = await Axios.get(`${BASE_URL}/getUsersListByUserId/${user.id}`);

        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            setUsersList(response.data);
            return response;
        }
    };

    const getHotelsListById = async () => {
        Axios.post(`${BASE_URL}/getHotelList`, { userId: userData.id })
            .then((response) => {
                setHotelsList(response.data);
            }).catch((error) => {
                setErrors({ response: error });
            });

    };

    const getDepartamentsList = async () => {
        Axios.post(`${BASE_URL}/getDepartamentList`, { userId: userData.id })
            .then((response) => {
                setDepartamentsList(response.data);
            }).catch((error) => {
                setErrors({ response: error });
            });
    };

    useEffect(() => {
        getUsersListByUserId();
        getHotelsListById();
        getDepartamentsList();
    }, [count]);

    console.log("usersListPArinte", usersList);

    return (
        <div>
            <TopMenu />
            <Menu />
            <div className="firstContainer">
                <div className="container" data-aos="fade-in">
                    <AddNewUserForm handleState={handleState} />
                    <hr />
                    <GetUsersList usersList={usersList} hotelsList={hotelsList} departamentsList={departamentsList} handleState={handleState} />
                </div>
                {errors.response && <div className="alert alert-danger">{errors.response}</div>}
            </div>
        </div>
    );
};

export default AddNewUser;