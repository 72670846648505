import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";

import HotelServicesMenu from "./hotelServicesMenu";
import Menu from "../../navigation/menu";
import TopMenu from "../../navigation/topMenu";
// import FooterMenu from "../navigation/footer";

const ClientPage = () => {
    const [hotelData, setHotelData] = React.useState([]);
    const [countyId, setCountyId] = React.useState(0);
    const [cityId, setCityId] = React.useState(0);
    const [departments, setDepartments] = React.useState({});
    const [payedOptions, setPayedOptions] = React.useState([]);
    const [wifiData, setWifiData] = React.useState([]);
    const [restaurantsList, setRestaurantsList] = React.useState([]);
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = React.useState({});

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const qr = searchParams.get("qr");
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const hotelId = qr.split("/")[1];
    const roomId = qr.split("/")[0];

    const getHotel = async (hotelId) => {
        try {
            const response = await Axios.get(`${BASE_URL}/getHotel/${hotelId}`);

            if (response.data.error) {
                setErrors({ responseH: response.data.error });
                return; // Opresc execuția în cazul unei erori
            }

            setHotelData(response.data);
            // setSolicitariPer24Hours(response.data[0].nrSolicitariPe24Ore);
            setCountyId(parseInt(response.data[0].judetul));
            setCityId(parseInt(response.data[0].localitatea));
        } catch (error) {
            console.error("Error fetching hotel data:", error);
            setErrors({ responseH: "A apărut o eroare la preluarea datelor." });
        }
    };

    const getCountyById = async (countyId) => {
        try {
            const response = await Axios.get(`${BASE_URL}/getCountyById/${countyId}`);

            if (response.data.error) {
                setErrors({ responseC: response.data.error });
                return; // Opresc execuția în cazul unei erori
            }
        } catch (error) {
            console.error("Error fetching county data:", error);
            setErrors({ responseC: "A apărut o eroare la preluarea datelor." });
        }
    };

    const getCityById = async (cityId) => {
        try {
            const response = await Axios.get(`${BASE_URL}/getCityById/${cityId}`);

            if (response.data.error) {
                setErrors({ responseCi: response.data.error });
                return; // Opresc execuția în cazul unei erori
            }
        } catch (error) {
            console.error("Error fetching city data:", error);
            setErrors({ responseCi: "A apărut o eroare la preluarea datelor." });
        }
    };

    const getWifi = async (hotelId) => {
        try {
            const response = await Axios.get(`${BASE_URL}/getWifi/${hotelId}`);

            if (response.data.error) {
                setErrors({ responseW: response.data.error });
                return; // Opresc execuția în cazul unei erori
            } else {
                setWifiData(response.data);
            }
        } catch (error) {
            console.error("Error fetching wifi data:", error);
            setErrors({ responseW: "A apărut o eroare la preluarea datelor." });
        }
    };

    const depToServ = (departments) => {
        const depNameToServNames = {};
        departments.forEach((item) => {
            const { depName, servName, id } = item;
            if (depName) {
                if (!depNameToServNames[depName]) {
                    depNameToServNames[depName] = [];
                }
                depNameToServNames[depName].push(`${id}/${servName}`);
            }
        });
        setDepartments(depNameToServNames);
    };

    const getDepartmentsByHotelId = async (hotelId) => {
        try {
            const response = await Axios.get(`${BASE_URL}/getDepartmentsByHotelId/${hotelId}`);

            if (response.data.error) {
                setErrors({ responseD: response.data.error });
                return; // Opresc execuția în cazul unei erori
            }
            depToServ(response.data);
        } catch (error) {
            console.error("Error fetching departments data:", error);
            setErrors({ responseD: "A apărut o eroare la preluarea datelor." });
        }
    };

    const getPayedOptionsByIdHotel = async (hotelId) => {
        try {
            const response = await Axios.post(`${BASE_URL}/getPayedOptions`, {
                idUnitate: hotelId
            });

            if (response.data && response.data.error) {
                setErrors({ responseP: "Nu au fost găsite opțiuni plătite." });
                return; // Opresc execuția în cazul unei erori
            } else {
                setPayedOptions(response.data);
            }
        } catch (error) {
            console.error("Error fetching payed options data:", error);
            setErrors({ responseP: "A apărut o eroare la preluarea datelor." });
        }
    };

    const getEvents = async (hotelId) => {
        try {
            const response = await Axios.post(`${BASE_URL}/getEvents`, {
                idUnitate: hotelId
            });

            if (response.data.error) {
                setErrors({ responseE: response.data.error });
                return; // Opresc execuția în cazul unei erori
            }
        } catch (error) {
            console.error("Error fetching events data:", error);
            setErrors({ responseE: "A apărut o eroare la preluarea datelor." });
        }
    };

    const getRestaurantsListByHotelId = async (hotelId) => {
        try {
            const response = await Axios.get(`${BASE_URL}/getRestaurantsListByHotelId/${hotelId}`);

            if (response.data.error) {
                setErrors({ responseR: response.data.error });
                return; // Opresc execuția în cazul unei erori
            } else {
                setRestaurantsList(response.data);
            }
        } catch (error) {
            console.error("Error fetching restaurants data:", error);
            setErrors({ responseR: "A apărut o eroare la preluarea datelor." });
        }
    };

    useEffect(() => {
        (async () => {
            if (hotelId) {
                await getHotel(hotelId);
                await getWifi(hotelId);
                await getDepartmentsByHotelId(hotelId);
                await getPayedOptionsByIdHotel(hotelId);
                await getEvents(hotelId);
                await getRestaurantsListByHotelId(hotelId);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (countyId)
                await getCountyById(countyId);
            if (cityId)
                await getCityById(cityId);
        })();
    }, [countyId, cityId]);

    return (
        <div>
            <TopMenu />
            <Menu />
            <HotelServicesMenu restaurantsList={restaurantsList} wifiData={wifiData} payedOptions={payedOptions} errors={errors} hotelData={hotelData} roomId={roomId} departments={departments} countyId={countyId} cityId={cityId} />
            {/* <FooterMenu /> */}
        </div>
    );
};

export default ClientPage;