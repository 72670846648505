import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import Axios from "axios";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import RestaurantForm from "./restaurantForm";
import RestaurantsList from "./restaurantsList";
import { FormattedMessage } from "react-intl";
import "./index.css";
import "../../App.css";

const Restaurant = () => {
  const [counter, setCounter] = useState(0);
  const [hotelList, setHotelList] = useState([]);
  const [restaurantsList, setRestaurantsList] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  if (!user) {
    navigate("/login");
    return null;
  }

  // const user = JSON.parse(localStorage.getItem("user"));
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const getHotelList = async () => {
    // setLoadingHotels(true);
    try {
      const response = await Axios.post(`${BASE_URL}/getHotelList`, { userId: user.id });
      if (response.data.error) {
        console.error(response.data.error);
      } else {
        setHotelList(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch hotel list", error);
    } finally {
      // setLoadingHotels(false);
    }
  };

  const getRestaurantsList = async () => {
    try {
      const response = await Axios.post(`${BASE_URL}/getRestaurantsList`, { userId: user.id });
      if (response.data.error) {
        console.error(response.data.error);
      } else {
        setRestaurantsList(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch events", error);
    }
  };

  const handleRestaurantSubmit = (restaurantData) => {
    Axios.post(`${BASE_URL}/addNewRestaurant`, restaurantData)
      .then((response) => {
        console.log("response", response);
        setCounter(counter + 1);
      })
      .catch((error) => {
        console.error("Error occurred during form submission:", error);
        setError({ submit: "A apărut o eroare la trimiterea formularului." });
      });
  };

  useEffect(() => {
    getHotelList();
    getRestaurantsList();
  }, [counter, user.id]); // Reload on changes

  return (
    <div>
      <TopMenu />
      <Menu />
      <div className="firstContainerRestaurants">
        <div className="container" data-aos="fade-in">
          <h2>
            <FormattedMessage id="restaurant.mainTitle" defaultMessage="Restaurante" />
          </h2>
          <h6>
            <FormattedMessage id="restaurant.subtitle" defaultMessage="Programul restaurantelor va fi adaugat aici." />
          </h6>
        </div>
      </div>
      <div className="containerForm">
        <RestaurantForm
          onChange={() => setCounter(counter + 1)}
          hotelsList={hotelList}
          restaurantsList={restaurantsList}
          userId={user.id}
          onRestaurantSubmit={handleRestaurantSubmit}
        />
        {error && <p className="error">{error.submit}</p>}
        <hr />
        <RestaurantsList
          onChange={() => setCounter(counter + 1)}
          hotelsList={hotelList}
          restaurantsList={restaurantsList}
        />

      </div>
      <Footer />
    </div>
  );
};

export default Restaurant;