import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import Axios from "axios";

import CheckInOutForm from "./checkInOutForm";
import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import CheckInOutList from "./checkInOutList";
import { FormattedMessage } from "react-intl";
import "./index.css";

const CheckInOut = () => {
  const [count, setCount] = useState(0);
  const [checkInOutList, setCheckInOutList] = useState([]);
  const [errors, setErrors] = useState({});
  const [hotelsList, setHotelsList] = useState([]);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  console.log("errors", errors);

  const { user } = useContext(UserContext);
  if (!user) {
    // Redirect to login if no user
    window.location.href = "/login";
    return null;
  }

  const handleState = () => {
    setCount(count + 1);
  };

  const userData = {
    userId: user.id,
  };

  const getHotelsList = async (userData) => {
    const response = await Axios.post(`${BASE_URL}/getHotelList`, userData);

    if (response.data.error) {
      setErrors({ response: response.data.error });
    } else {
      setHotelsList(response.data);
    }
  };

  const getCheckInOutList = async (userData) => {
    const response = await Axios.post(`${BASE_URL}/getCheckInOutList`, userData);

    if (response.data.error) {
      setErrors({ response: response.data.error });
    } else {
      setCheckInOutList(response.data);
    }
  };

  useEffect(() => {
    (async () => {
      getCheckInOutList(userData);
      getHotelsList(userData);
    }
    )();
  }, [count]);
  console.log("hotelsList", hotelsList);
  console.log("checkInOutList", checkInOutList);
  return (
    <div>
      <TopMenu />
      <Menu />
      <div className="firstCheckInOutContainer">
        <div className="checkInOutContainer" data-aos="fade-in">
          <h2>
            <FormattedMessage id="checkInOut.mainTitle" defaultMessage="Pagina Check-In/Out" />
          </h2>
          <h6>
            <FormattedMessage id="checkInOut.subtitle" defaultMessage="adauga orele de check-in si check-out" />
          </h6>
        </div>
      </div>
      <div className="containerCheckInOutForm">
        {checkInOutList && hotelsList && checkInOutList.length !== hotelsList.length && (
          <>
            <CheckInOutForm handleState={handleState} hotelsList={hotelsList} />
            <hr />
          </>
        )}
        <CheckInOutList checkInOutList={checkInOutList} hotelsList={hotelsList} handleState={handleState} />
      </div>
      <Footer />
    </div>
  );
};

export default CheckInOut;