import React, { useState } from "react";
import Axios from "axios";
import { Form, InputGroup } from "react-bootstrap";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const AddNewDepartament = (props) => {
    // console.log({ "props": props });
    const [departament, setDepartament] = useState("");
    const [hotelId, setHotelId] = useState("");
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    // const userData = JSON.parse(localStorage.getItem("user"));
    const intl = useIntl();

    const navigate = useNavigate();
    const nextStep = () => {
        navigate("/members/addNewUser");
    };

    const validateValues = (inputValues) => {
        let errors = {};
        if (!inputValues.departament) {
            errors.departament = intl.formatMessage({ id: "addNewDep.error1", defaultMessage: "Denumirea departamentului este obligatorie" });
        }
        if (!inputValues.hotelId) {
            errors.hotelId = intl.formatMessage({ id: "addNewDep.error2", defaultMessage: "Trebuie sa alegi un hotel" });
        }
        return errors;
    };

    const addNewDepartament = (e) => {
        e.preventDefault();
        setErrors(validateValues({ departament, hotelId }));
        Axios.post(`${BASE_URL}/addNewDepartament`, {
            departament: departament,
            hotelId: hotelId,
        }).then((response) => {
            setMessage(response.data.message);
            setDepartament("");
            setHotelId("0");
            setErrors({});
            props.setSubmitedChanges();
        }).catch((error) => {
            console.error(error);
        });
    };

    return (
        <div className="formContent  d-flex align-items-center justify-content-center">
            <div className="">
                <h1 className="text-center"><FormattedMessage id="addNewDep.title" defaultMessage="Adauga departament nou" /></h1>
                <Form>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addNewDep.denumireDepartament" defaultMessage="Denumire" /></InputGroup.Text>
                        <Form.Control
                            type="text"
                            value={departament}
                            onChange={(event) => { setDepartament(event.target.value); }}
                            className="inputLabel"
                            id="denumireDepartament"
                        />
                        {errors.departament && <p className="error">{errors.departament}</p>}
                    </InputGroup>

                    <InputGroup style={{ marginTop: 10 }}>
                        {/* <Form.Label><FormattedMessage id="addNewDep.alegeHotel" defaultMessage="Alege hotelul" /></Form.Label> */}
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addNewDep.alegeHotel" defaultMessage="Alege hotelul" /></InputGroup.Text>
                        <Form.Control
                            as="select"
                            value={hotelId}
                            onChange={(event) => { setHotelId(event.target.value); }}
                            className="inputLabel"
                            id="selectIdHotel"
                        >
                            <option value="0"><FormattedMessage id="addNewDep.select" defaultMessage="Selecteaza hotelul" /></option>
                            {props.hotelsList?.map((val, key) => {
                                return (
                                    <option key={key} value={val.idUnitate}>{val.denumire}</option>
                                );
                            })}
                        </Form.Control>
                        {errors.hotelId && <p className="error">{errors.hotelId}</p>}
                    </InputGroup>
                    <div className="form-group text-center" style={{ paddingTop: 20 }}>
                        <button type="submit" onClick={addNewDepartament} className="universalButton buttonUniv btn-rounded waves-effect">
                            <FormattedMessage id="addNewDep.button" defaultMessage="Adauga departamentul" />
                        </button>
                        <button className="universalButtonGreen buttonUnivGreen btn-rounded waves-effect" onClick={nextStep}>
                            <FormattedMessage id="addUnit.pasul_urmator" defaultMessage="Pasul urmator" />
                        </button>
                    </div>
                </Form>
                <h1>{message}</h1>
            </div>
        </div>
    );
};

export default AddNewDepartament;