import * as React from "react";
import { useEffect } from "react";
import Switch from "@mui/material/Switch";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import EuroIcon from "@mui/icons-material/Euro";
import Axios from "axios";

import "./index.css";
import "bootstrap-icons/font/bootstrap-icons.css";

export default function PayedOptionsForm(props) {
    console.log("props PayedOptionsForm", { props });
    const [checkedConfigIds, setCheckedConfigIds] = React.useState([]);
    const [payedOptionsAndConfigTableArray, setPayedOptionsAndConfigTableArray] = React.useState([]);
    // eslint-disable-next-line
    const [errors, setErrors] = React.useState({});
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const createPayedOptionsArrayFromProps = (props) => {
        const payedOptionsArray = [];
        for (let i = 0; i < props.payedOptions.length; i++) {
            for (let j = 0; j < props.payedOptionsConfigTable.length; j++) {
                if (props.payedOptions[i].optiune === props.payedOptionsConfigTable[j].config_name) {
                    const newArray = { ...props.payedOptions[i], ...props.payedOptionsConfigTable[j] };
                    payedOptionsArray.push(newArray);
                }
            }
        }
        return payedOptionsArray;
    };

    const setOptionStatus = async (status, idPayedOption) => {
        const response = await Axios.post(`${BASE_URL}/setOptionStatus`, { status, idPayedOption });
        // console.log({ response: response });
        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            return response.data;
        }
    };

    const handleToggle = (value, idPayedOption) => () => {
        const currentIndex = checkedConfigIds.indexOf(value);
        const newChecked = [...checkedConfigIds];

        if (currentIndex === -1) {
            newChecked.push(value);
            setOptionStatus(1, idPayedOption);
        } else {
            newChecked.splice(currentIndex, 1);
            setOptionStatus(0, idPayedOption);
        }
        setCheckedConfigIds(newChecked);
    };

    const setActivePayedOptions = (payedOptionsAndConfigTableArray) => {
        const newCheckedConfigIds = [];
        if (payedOptionsAndConfigTableArray) {
            for (let i = 0; i < payedOptionsAndConfigTableArray.length; i++) {
                if (payedOptionsAndConfigTableArray[i].esteActiv !== 0) {
                    newCheckedConfigIds.push(payedOptionsAndConfigTableArray[i].optiune);
                }
            }
        }
        setCheckedConfigIds(newCheckedConfigIds);
    };

    useEffect(() => {
        const payedOptionsArray = createPayedOptionsArrayFromProps(props);
        setPayedOptionsAndConfigTableArray(payedOptionsArray);
        setActivePayedOptions(payedOptionsArray);
    }
        , [props]);

    return (
        <div className="containerPayedOption" data-aos="fade-in">
            {/* <List
                sx={{ width: "100%", maxWidth: "50vh", bgcolor: "background.paper" }}
                subheader={
                    <ListSubheader>
                        <FormattedMessage id="payedOption.subtitle1" defaultMessage="Aceste opțiuni vor apărea pe pagina ta și sunt disponibile contra unui cost suplimentar." />
                        <FormattedMessage id="payedOption.subtitle2" defaultMessage="Alege-le acum pentru o experiență completă!" />
                    </ListSubheader>}
            ></List> */}
            {payedOptionsAndConfigTableArray.map((payedOption, index) => (
                <ListItem key={index}>
                    <ListItemIcon>
                    </ListItemIcon>
                    <ListItemText primary={payedOption.optiune} />
                    {checkedConfigIds.indexOf(payedOption.optiune) !== -1 ? <span>{payedOption.config_option}<EuroIcon sx={{ fontSize: 16 }} /></span> : <span>{" "} 0 <EuroIcon sx={{ fontSize: 16 }} /></span>}
                    <Switch
                        onChange={handleToggle(payedOption.optiune, payedOption.idPayedOption)}
                        checked={checkedConfigIds.indexOf(payedOption.optiune) !== -1}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                </ListItem>
            ))}
        </div>
    );
}
