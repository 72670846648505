import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";

import { EditDepButton } from "./modal/edit";
import { DeleteDepButton } from "./modal/delete";

const GetDepartamentList = (props) => {
    const [departamentList, setDepartamentList] = useState([]);
    // const [submitedChanges, setSubmitedChanges] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({});
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const user = JSON.parse(localStorage.getItem("user"));
    const userData = {
        userId: user.id,
    };

    const getDepartamentList = async (userData) => {
        const response = await Axios.post(`${BASE_URL}/getDepartamentList`, userData);

        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            setDepartamentList(response.data);
            return response;
        }
    };
    useEffect(() => {
        getDepartamentList(userData);
    }, [props.count]);

    return (
        <>
            <div className="" style={{ paddingTop: 20 }} >
                <h1 className="text-center">
                    <FormattedMessage
                        id="depList.title"
                        defaultMessage="Lista unitatilor mele"
                    />
                </h1>
            </div>
            <div className="table-responsive" style={{ paddingTop: 20, maxWidth: 700, marginLeft: "auto", marginRight: "auto" }} >
                {departamentList.length != 0 && <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" style={{ width: 30 }}>#</th>
                            <th scope="col"><FormattedMessage id="depList.hotelName" defaultMessage="Numele hotelului" /></th>
                            <th scope="col"><FormattedMessage id="depList.departamentName" defaultMessage="Numele departamentului" /></th>
                            <th scope="col" className="thButtonSpace"><FormattedMessage id="depList.edit" defaultMessage="Edit" /></th>
                            <th scope="col" className="thButtonSpace"><FormattedMessage id="depList.delete" defaultMessage="Delete" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {departamentList.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{val.unitName}</td>
                                    <td>{val.departName}</td>
                                    <td>
                                        <EditDepButton denumireDepartament={val.departName} hotelId={val.id_unitate} idDep={val.id} hotelsList={props.hotelsList} onSubmitedChanges={props.setSubmitedChanges} />
                                    </td>
                                    <td>
                                        <DeleteDepButton denumireUnitate={val.unitName} denumireDepartament={val.departName} idDep={val.id} onSubmitedChanges={props.setSubmitedChanges} />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                }
                {departamentList.length == 0 && <div className="alert alert-danger" role="alert">
                    <FormattedMessage id="depList.noDep" defaultMessage="Nu exista departamente" />
                </div>}
            </div>
        </>
    );
};

export default GetDepartamentList;