import React, { useState } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Form } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

export const EditWifiButton = (props) => {
  console.log("props", props);

  // Inițializarea stării ca obiect
  const [inputFields, setInputFields] = useState({
    network: props.network,
    security: props.security,
    password: props.password,
    hotel: props.idHotel,
  });

  // const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleEdit = async (event) => {
    event.preventDefault();
    const dataToSend = {
      wifiId: props.idWifi,
      network: inputFields.network,
      security: inputFields.security,
      password: inputFields.password,
      idHotel: inputFields.hotel,
      userId: user.id,
    };

    const response = await Axios.post(`${BASE_URL}/editWifi`, dataToSend);
    if (response.data.error) {
      setErrors({ response: response.data.error });
    } else {
      // setMessage(response.data.message);
      props.onChange();
      handleClose();
      return response;
    }
  };

  // Funcția de gestionare a schimbărilor
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleEdit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="editWifi.title" defaultMessage="Editeaza reteaua WiFi " />
              {props.network}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="editWifi.network" defaultMessage="Nume retea WiFi" />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Nume retea WiFi"
                name="network"
                value={inputFields.network}
                onChange={handleInputChange}
              />
            </Form.Group>
            {errors.network && <p className="error">{errors.network}</p>}

            <Form.Group>
              <Form.Label>
                <FormattedMessage id="editWifi.security" defaultMessage="Securitate" />
              </Form.Label>
              <Form.Control
                as="select"
                name="security"
                value={inputFields.security}
                onChange={handleInputChange}
              >
                <option value="0">
                  <FormattedMessage id="editWifi.securityType" defaultMessage="Tipul de securitate" />
                </option>
                <option value="OPEN">OPEN</option>
                <option value="WEP">WEP</option>
                <option value="WPA">WPA</option>
                <option value="WPA2">WPA2</option>
                <option value="WPA3">WPA3</option>
              </Form.Control>
            </Form.Group>
            {errors.security && <p className="error">{errors.security}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="editWifi.password" defaultMessage="Parola" />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Parola"
                name="password"
                value={inputFields.password}
                onChange={handleInputChange}
              />
            </Form.Group>
            {errors.password && <p className="error">{errors.password}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="editWifi.hotel" defaultMessage="Hotel" />
              </Form.Label>
              <Form.Control
                as="select"
                name="hotel"
                value={inputFields.hotel}
                onChange={handleInputChange}
              >
                {props.hotelsList?.map((hotel, index) => {
                  return (
                    <option key={index} value={hotel.idUnitate}>
                      {hotel.denumire}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="editWifi.close" defaultMessage="Inchide" />
            </Button>
            <Button variant="success" onClick={handleClose} type="submit">
              <FormattedMessage id="editWifi.save" defaultMessage="Salveaza" />
            </Button>
          </Modal.Footer>
          {/* {message && <p className="success">{message}</p>} */}
        </Form>
      </Modal>
    </>
  );
};
