import React, { useEffect, useState } from "react";
import Axios from "axios";

import AddWifiForm from "./wifiForm";
import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import WifiList from "./wifiList";
import { FormattedMessage } from "react-intl";
import "./index.css";

const Wifi = () => {
  const [wifiList, setWifiList] = useState([]);
  const [hotelsList, setHotelsList] = useState([]);
  const [errors, setErrors] = useState({});
  const [count, setCount] = useState(0);

  const user = JSON.parse(localStorage.getItem("user"));
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  console.log(errors);

  if (!user) {
    // Redirect to login if no user
    window.location.href = "/login";
    return null;
  }

  const handleState = () => {
    setCount(count + 1);
  };

  const getWifiList = async (userData) => {
    const response = await Axios.post(`${BASE_URL}/getWifiList`, userData);

    if (response.data.error) {
      setErrors({ response: response.data.error });
    } else {
      setWifiList(response.data);
    }
  };

  useEffect(() => {
    Axios.post(`${BASE_URL}/getHotelList`, { userId: user.id }).then((response) => {
      setHotelsList(response.data);
    });
  }, []);

  const userData = {
    userId: user.id,
  };

  useEffect(() => {
    (async () => {
      getWifiList(userData);
    }
    )();
  }, [count]);

  return (
    <div>
      <TopMenu />
      <Menu />
      <div className="firstWifiContainer">
        <div className="wifiContainer" data-aos="fade-in">
          <h2>
            <FormattedMessage id="wifi.mainTitle" defaultMessage="Pagina WiFi" />
          </h2>
          <h6>
            <FormattedMessage id="wifi.subtitle" defaultMessage="adauga o retea noua de wifi" />
          </h6>
        </div>
      </div>
      <div className="containerWifiNetworksForm">
        <AddWifiForm hotelsList={hotelsList} handleState={handleState} />
        <hr />
        <WifiList wifiList={wifiList} hotelsList={hotelsList} handleState={handleState} />
      </div>
      <Footer />
    </div>
  );
};

export default Wifi;