import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, FormSelect, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
// import IBANValidator from "../utils/ibanValidator";
import * as IBAN from "ibantools";

function Select({ options, value, title, handleSelectChange }) {
  return (
    <FormSelect name="title" value={value ? value : ""} onChange={handleSelectChange} >
      <option value="" disabled selected hidden>{title}</option>
      {options.map(option =>
        <option key={option.id} value={option.id} >
          {option.name}
        </option>
      )}
    </FormSelect>
  );
}

const AddNewHotel = (props) => {
  const [inputFields, setinputFields] = useState({
    hotelName: "",
    hotelAddress: "",
    hotelCity: "",
    hotelCounty: "",
    hotelPhone: "",
    hotelEmail: "",
    hotelCheckin: "",
    hotelCheckout: "",
    hotelMicDejun: "",
    hotelPranz: "",
    hotelCina: "",
    societate: "",
    cui: "",
    nrRegCom: "",
    adresaSocietate: "",
    emailSocietate: "",
    telefonSocietate: "",
    contBancar: "",
    banca: "",
    reprezentantLegal: "",
  });
  const [errors, setErrors] = useState({});
  // const [isLoggedIn, setLoginStatus] = useState(false);
  const [countyList, setCountyList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [isValid, setIsValid] = useState(false);

  const [selectedCountyId, setSelectedCountyId] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleCountryChange = (e) => {
    setSelectedCityId(null);
    setSelectedCountyId(e.target.value);
    setinputFields({
      ...inputFields,
      hotelCounty: e.target.value,
    });
  };

  const navigate = useNavigate();
  const nextStep = () => {
    navigate("/members/addNewDepartament");
  };

  const handleStateChange = (e) => {
    setSelectedCityId(e.target.value);
    setinputFields({
      ...inputFields,
      hotelCity: e.target.value,
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "contBancar") {
      setIsValid(IBAN.isValidIBAN(event.target.value));
    }
    setinputFields({
      ...inputFields,
      [event.target.name]: event.target.value,
    });
  };

  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.hotelName) {
      errors.hotelName = <FormattedMessage id="addUnit.nume_hotel_error" defaultMessage="Numele hotelului este obligatoriu" />;
    }
    if (!inputValues.hotelAddress) {
      errors.hotelAddress = <FormattedMessage id="addUnit.adresa_hotel_error" defaultMessage="Adresa hotelului este obligatorie" />;
    }
    if (!inputValues.hotelCity) {
      errors.hotelCity = <FormattedMessage id="addUnit.localitate_hotel_error" defaultMessage="Localitatea hotelului este obligatoriu" />;
    }
    if (!inputValues.hotelCounty) {
      errors.hotelCounty = <FormattedMessage id="addUnit.judet_hotel_error" defaultMessage="Judetul hotelului este obligatorie" />;
    }
    if (!inputValues.hotelPhone) {
      errors.hotelPhone = <FormattedMessage id="addUnit.telefon_hotel_error" defaultMessage="Telefonul hotelului este obligatoriu" />;
    }
    if (inputValues.hotelPhone && !inputValues.hotelPhone.match(/^[0-9]+$/)) {
      errors.hotelPhone = <FormattedMessage id="addUnit.telefon_hotel_error2" defaultMessage="Telefonul hotelului trebuie sa contina doar cifre" />;
    }
    if (!inputValues.hotelEmail) {
      errors.hotelEmail = <FormattedMessage id="addUnit.email_hotel_error" defaultMessage="Emailul hotelului este obligatoriu" />;
    }
    if (inputValues.hotelEmail && !inputValues.hotelEmail.match(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)) {
      errors.hotelEmail = <FormattedMessage id="addUnit.email_hotel_error2" defaultMessage="Emailul hotelului trebuie sa fie de forma:email@myserver.ro" />;
    }
    if (!inputValues.hotelCheckin) {
      errors.hotelCheckin = <FormattedMessage id="addUnit.checkin_hotel_error" defaultMessage="Check-in-ul este obligatoriu" />;
    }
    if (!inputValues.hotelCheckout) {
      errors.hotelCheckout = <FormattedMessage id="addUnit.checkout_hotel_error" defaultMessage="Check-out-ul este obligatoriu" />;
    }
    if (!inputValues.societate) {
      errors.societate = <FormattedMessage id="addUnit.societate_error" defaultMessage="Societatea este obligatorie" />;
    }
    if (!inputValues.cui) {
      errors.cui = <FormattedMessage id="addUnit.cui_error" defaultMessage="CUI-ul este obligatoriu" />;
    }
    if (!inputValues.nrRegCom) {
      errors.nrRegCom = <FormattedMessage id="addUnit.nrRegCom_error" defaultMessage="Nr. Reg. Comertului este obligatoriu" />;
    }
    if (!inputValues.adresaSocietate) {
      errors.adresaSocietate = <FormattedMessage id="addUnit.adresaSocietate_error" defaultMessage="Adresa societatii este obligatorie" />;
    }
    if (!inputValues.emailSocietate) {
      errors.emailSocietate = <FormattedMessage id="addUnit.emailSocietate_error" defaultMessage="Emailul societatii este obligatoriu" />;
    }
    if (inputValues.emailSocietate && !inputValues.emailSocietate.match(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)) {
      errors.emailSocietate = <FormattedMessage id="addUnit.emailSocietate_error2" defaultMessage="Emailul societatii trebuie sa fie de forma: email@myEmail.ro" />;
    }
    if (!inputValues.telefonSocietate) {
      errors.telefonSocietate = <FormattedMessage id="addUnit.telefonSocietate_error" defaultMessage="Telefonul societatii este obligatoriu" />;
    }
    if (inputValues.telefonSocietate && !inputValues.telefonSocietate.match(/^[0-9]+$/)) {
      errors.telefonSocietate = <FormattedMessage id="addUnit.telefonSocietate_error2" defaultMessage="Telefonul societatii trebuie sa contina doar cifre" />;
    }
    if (!inputValues.contBancar) {
      errors.contBancar = <FormattedMessage id="addUnit.contBancar_error" defaultMessage="Contul bancar este obligatoriu" />;
    }
    if (!isValid) {
      errors.contBancar = <FormattedMessage id="addUnit.contBancar_error2" defaultMessage="Contul bancar nu este valid" />;
    }
    if (!inputValues.banca) {
      errors.banca = <FormattedMessage id="addUnit.banca_error" defaultMessage="Banca este obligatorie" />;
    }
    if (!inputValues.reprezentantLegal) {
      errors.reprezentantLegal = <FormattedMessage id="addUnit.reprezentantLegal_error" defaultMessage="Reprezentantul legal este obligatoriu" />;
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    props.setCount(props.count + 1);
    const errors = validateValues(inputFields);
    const user = JSON.parse(localStorage.getItem("user"));

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      const hotelData = {
        hotelName: inputFields.hotelName,
        hotelAddress: inputFields.hotelAddress,
        hotelCity: inputFields.hotelCity,
        hotelCounty: inputFields.hotelCounty,
        hotelPhone: inputFields.hotelPhone,
        hotelEmail: inputFields.hotelEmail,
        hotelCheckIn: inputFields.hotelCheckin,
        hotelCheckOut: inputFields.hotelCheckout,
        hotelBreakfast: inputFields.hotelMicDejun,
        hotelLunch: inputFields.hotelPranz,
        hotelDinner: inputFields.hotelCina,
        societate: inputFields.societate,
        cui: inputFields.cui,
        nrRegCom: inputFields.nrRegCom,
        adresaSocietate: inputFields.adresaSocietate,
        emailSocietate: inputFields.emailSocietate,
        telefonSocietate: inputFields.telefonSocietate,
        contBancar: inputFields.contBancar,
        banca: inputFields.banca,
        reprezentantLegal: inputFields.reprezentantLegal,
        userId: user.id,
      };

      const createPayedOptions = async (hotelId) => {
        const response = await Axios.post(`${BASE_URL}/createPayedOptions`, { hotelId });

        if (response.data.error) {
          setErrors({ response: response.data.error });
        } else {
          return response.data;
        }
      };

      const response = await Axios.post(`${BASE_URL}/addNewHotel`, hotelData);
      if (response.data.error) {
        setErrors({ response: response.data.error });
      } else {
        setErrors({});
        const hotelId = response.data[0].last_id;

        await createPayedOptions(hotelId);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const countyList = await Axios.post(`${BASE_URL}/getCountyList`);
      setCountyList(countyList.data);
    })();
  }
    , []);

  useEffect(() => {
    (async () => {

      const citiesList = await Axios.post(`${BASE_URL}/getCitiesList`);
      setCitiesList(citiesList.data);

    })();
    // }
  }, []);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="">
        <h1 className="text-center"><FormattedMessage id="addUnit.title" defaultMessage="Adauga hotel nou" /></h1><br />
        <Form onSubmit={handleSubmit}>

          <InputGroup>
            <InputGroup.Text style={{ width: 130 }} >
              <FormattedMessage
                id="addUnit.nume_hotel"
                defaultMessage="Denumire hotel"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="hotelName" onChange={handleChange} className="inputLabel" />
            {errors.hotelName && <p className="error">{errors.hotelName}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }} >
              <FormattedMessage
                id="addUnit.adresa_hotel"
                defaultMessage="Adresa hotel"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="hotelAddress" onChange={handleChange} className="inputLabel" />
            {errors.hotelAddress && <p className="error">{errors.hotelAddress}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.judet_hotel"
                defaultMessage="Judetul"
              />
            </InputGroup.Text>

            <Select
              options={countyList}
              value={selectedCountyId}
              title={<FormattedMessage id="addUnit.selecteaza_judetul" defaultMessage="Selecteaza judetul" />}
              handleSelectChange={handleCountryChange}
              className="inputLabel"
            />

            {errors.hotelCounty && <p className="error">{errors.hotelCounty}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.localitate_hotel"
                defaultMessage="Localitatea"
              />
            </InputGroup.Text>

            <Select
              options={citiesList.filter(city => city.county_id === parseInt(selectedCountyId))}
              value={selectedCityId}
              title={<FormattedMessage id="addUnit.selecteaza_localitatea" defaultMessage="Selecteaza localitatea" />}
              handleSelectChange={handleStateChange}
              className="inputLabel"
            />

            {errors.hotelCity && <p className="error">{errors.hotelCity}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.telefon_hotel"
                defaultMessage="Telefon hotel"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="hotelPhone" onChange={handleChange} className="inputLabel" />
            {errors.hotelPhone && <p className="error">{errors.hotelPhone}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.email_hotel"
                defaultMessage="Email hotel"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="hotelEmail" onChange={handleChange} className="inputLabel" />
            {errors.hotelEmail && <p className="error">{errors.hotelEmail}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.stele_hotel"
                defaultMessage="Stele hotel"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="hotelStars" onChange={handleChange} className="inputLabel" />
            {errors.hotelStars && <p className="error">{errors.hotelStars}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.checkin_hotel"
                defaultMessage="Check-in hotel"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="hotelCheckin" onChange={handleChange} className="inputLabel" />
            {errors.hotelCheckin && <p className="error">{errors.hotelCheckin}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.checkout_hotel"
                defaultMessage="Check-out hotel"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="hotelCheckout" onChange={handleChange} className="inputLabel" />
            {errors.hotelCheckout && <p className="error">{errors.hotelCheckout}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.mic_dejun_hotel"
                defaultMessage="Mic dejun hotel"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="hotelMicDejun" onChange={handleChange} className="inputLabel" />
            {errors.hotelMicDejun && <p className="error">{errors.hotelMicDejun}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.pranz_hotel"
                defaultMessage="Pranz hotel"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="hotelPranz" onChange={handleChange} className="inputLabel" />
            {errors.hotelPranz && <p className="error">{errors.hotelPranz}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.cina_hotel"
                defaultMessage="Cina hotel"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="hotelCina" onChange={handleChange} className="inputLabel" />
            {errors.hotelCina && <p className="error">{errors.hotelCina}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage
                id="addUnit.societate"
                defaultMessage="Societate"
              />
            </InputGroup.Text>
            <Form.Control type="text" name="societate" onChange={handleChange} className="inputLabel" />
            {errors.societate && <p className="error">{errors.societate}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage id="addUnit.cui" defaultMessage="CUI" />
            </InputGroup.Text>
            <Form.Control type="text" name="cui" onChange={handleChange} className="inputLabel" />
            {errors.cui && <p className="error">{errors.cui}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage id="addUnit.nrRegCom" defaultMessage="NRC" />
            </InputGroup.Text>
            <Form.Control type="text" name="nrRegCom" onChange={handleChange} className="inputLabel" />
            {errors.nrRegCom && <p className="error">{errors.nrRegCom}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage id="addUnit.adresaSocietate" defaultMessage="adresaSocietate" />
            </InputGroup.Text>
            <Form.Control type="text" name="adresaSocietate" onChange={handleChange} className="inputLabel" />
            {errors.adresaSocietate && <p className="error">{errors.adresaSocietate}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage id="addUnit.emailSocietate" defaultMessage="Email societate" />
            </InputGroup.Text>
            <Form.Control type="text" name="emailSocietate" onChange={handleChange} className="inputLabel" />
            {errors.emailSocietate && <p className="error">{errors.emailSocietate}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage id="addUnit.telefonSocietate" defaultMessage="Telefon societate" />
            </InputGroup.Text>
            <Form.Control type="text" name="telefonSocietate" onChange={handleChange} className="inputLabel" />
            {errors.telefonSocietate && <p className="error">{errors.telefonSocietate}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage id="addUnit.contBancar" defaultMessage="Cont bancar" />
            </InputGroup.Text>
            <Form.Control type="text" name="contBancar" onChange={handleChange} className="inputLabel" />
            {/* <IBANValidator  /> */}
            {errors.contBancar && <p className="error">{errors.contBancar}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage id="addUnit.banca" defaultMessage="Banca" />
            </InputGroup.Text>
            <Form.Control type="text" name="banca" onChange={handleChange} className="inputLabel" />
            {errors.banca && <p className="error">{errors.banca}</p>}
          </InputGroup>

          <InputGroup style={{ marginTop: 10 }}>
            <InputGroup.Text style={{ width: 130 }}>
              <FormattedMessage id="addUnit.reprezentantLegal" defaultMessage="Reprezentant" />
            </InputGroup.Text>
            <Form.Control type="text" name="reprezentantLegal" onChange={handleChange} className="inputLabel" />
            {errors.reprezentantLegal && <p className="error">{errors.reprezentantLegal}</p>}
          </InputGroup>

          <div className="form-group text-center" style={{ marginTop: 10 }}>
            <button type="submit" className="universalButton buttonUniv btn-rounded waves-effect">
              <FormattedMessage
                id="addUnit.adauga_hotel"
                defaultMessage="Adauga hotel"
              />
            </button>
            <button className="universalButtonGreen buttonUnivGreen btn-rounded waves-effect" onClick={nextStep}>
              <FormattedMessage
                id="addUnit.pasul_urmator"
                defaultMessage="Pasul urmator"
              />
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddNewHotel;