import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Axios from "axios";
import { FormattedMessage } from "react-intl";

const EditReviewButton = (props) => {
  console.log("propsEdit", props);
  const [show, setShow] = useState(false);
  const [inputFields, setinputFields] = useState({
    idReview: props.idReview,
    review: props.denumire,
    url: props.url,
    idUnitate: props.idUnitate,
    idUser: props.idUser,
  });
  const [errors, setErrors] = useState({});
  const handleClose = () => setShow(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  React.useEffect(() => {
    setinputFields({
      idReview: props.idReview,
      review: props.denumire,
      url: props.url,
      idUnitate: props.idUnitate,
      idUser: props.idUser,
    });
  }, [props]);

  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.review) {
      errors.review = <FormattedMessage id="editReview.addReviewErr" defaultMessage="Adauga recenzia" />;
    }
    if (!inputValues.url) {
      errors.url = <FormattedMessage id="editReview.addUrlErr" defaultMessage="Adauga adresa url a paginii de socializare" />;
    }
    if (Number(inputValues.idUnitate) === "0" || !inputValues.idUnitate) {
      errors.idUnitate = <FormattedMessage id="editReview.selectHotelErr" defaultMessage="Alege hotelul" />;
    }
    return errors;
  };

  const resetForm = () => {
    setinputFields({
      idReview: props.idReview,
      review: props.denumire,
      url: props.url,
      idUnitate: props.idUnitate,
      idUser: props.idUser,
    });
    setErrors({});
  };

  const handleChange = (event) => {
    setinputFields({
      ...inputFields,
      [event.target.name]: event.target.value,
    });
  };

  const handleShow = () => {
    resetForm(); // Resetează starea cu valorile din props
    setShow(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateValues(inputFields);
    if (Object.keys(validationErrors).length === 0) {
      const data = {
        idReview: inputFields.idReview,
        review: inputFields.review,
        url: inputFields.url,
        idUnitate: inputFields.idUnitate,
        userId: user.id,
      };
      Axios.post(`${BASE_URL}/editReview`, data)
        .then((response) => {
          if (!response.data.error) {
            props.onSubmitedChanges(); // Incrementare în componenta părinte
            resetForm();
            setShow(false);
          } else {
            setErrors({ response: response.data.error });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title><FormattedMessage id="editReview.editReview" defaultMessage="Editeaza recenzia" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formReview">
              <Form.Label><FormattedMessage id="editReview.review" defaultMessage="Recenzie" /></Form.Label>
              <Form.Control
                type="text"
                name="review"
                value={inputFields.review}
                onChange={handleChange}
                placeholder="Adauga recenzia"
              />
              {errors.review && <p className="text-danger">{errors.review}</p>}
            </Form.Group>
            <Form.Group controlId="formUrl">
              <Form.Label><FormattedMessage id="editReview.url" defaultMessage="Url" /></Form.Label>
              <Form.Control
                type="text"
                name="url"
                value={inputFields.url}
                onChange={handleChange}
                placeholder="Adauga url"
              />
              {errors.url && <p className="text-danger">{errors.url}</p>}
            </Form.Group>

            <Form.Group controlId="formHotel">
              <Form.Label><FormattedMessage id="editReview.hotel" defaultMessage="Hotel" /></Form.Label>
              <Form.Control
                as="select"
                name="idUnitate"
                value={String(inputFields.idUnitate)}
                onChange={handleChange}
              >
                <option value="0">Alege hotelul</option>
                {props.hotelsList.map((hotel, index) => {
                  return (
                    <option key={index} value={String(hotel.idUnitate)}>{hotel.denumire}</option>
                  );
                })}
              </Form.Control>
              {errors.idUnitate && <p className="text-danger">{errors.idUnitate}</p>}
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="editReview.close" defaultMessage="Inchide" />
            </Button>
            <Button variant="success" type="submit">
              <FormattedMessage id="editReview.save" defaultMessage="Salveaza" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div >
  );
};

export default EditReviewButton;