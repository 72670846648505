import React from "react";

import { FormattedMessage } from "react-intl";
// import { DeleteWifiButton } from "./modal/delete";
import { EditWifiButton } from "./modal/edit";
import { Table } from "react-bootstrap";

const CheckInOutList = (props) => {
    console.log("checkInOutList", props.checkInOutList);
    return (
        <div>
            <div className="" style={{ paddingTop: 20, paddingBottom: 20 }} >
                <h1 className="text-center">
                    <FormattedMessage
                        id="checkInOut.title"
                        defaultMessage="Lista orelor de check-In/Out"
                    />
                </h1>
            </div>
            <Table responsive style={{ paddingTop: 20, maxWidth: 700, marginLeft: "auto", marginRight: "auto" }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th><FormattedMessage id="checkInOut.check-in" defaultMessage="Ora check-In" /></th>
                        <th><FormattedMessage id="checkInOut.check-out" defaultMessage="Ora check-Out" /></th>
                        <th><FormattedMessage id="checkInOut.hotel" defaultMessage="Hotel" /></th>
                        <th><FormattedMessage id="checkInOut.edit" defaultMessage="Editeaza" /></th>
                        {/* <th><FormattedMessage id="checkInOut.delete" defaultMessage="Sterge" /></th> */}
                    </tr>
                </thead>
                <tbody>
                    {props.checkInOutList.map((checkInOut, index) => {
                        return (
                            <tr key={checkInOut.idHotel}>
                                <td>{index + 1}</td>
                                <td>{checkInOut.checkIn}</td>
                                <td>{checkInOut.checkOut}</td>
                                <td>{checkInOut.hotel}</td>
                                <td>
                                    <EditWifiButton
                                        checkIn={checkInOut.checkIn}
                                        checkOut={checkInOut.checkOut}
                                        hotelName={checkInOut.hotel}
                                        idHotel={checkInOut.idHotel}
                                        hotelsList={props.hotelsList}
                                        onChanges={props.handleState}
                                    />
                                </td>
                                {/* <td>
                                    <DeleteWifiButton
                                        idWifi={checkInOut.idWifi}
                                        network={checkInOut.network}
                                        hotelName={checkInOut.hotel}
                                        onChanges={setSubmitedChanges}
                                    />
                                </td> */}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default CheckInOutList;