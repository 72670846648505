import React, { useState, useMemo, useEffect } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import QRCode from "qrcode";
import MyPagination from "../utils/pagination/index";
import { DeleteQrButton } from "./modal/delete";
import { EditQrButton } from "./modal/edit";
import { OpenImageButton } from "./modal/openImage";
// import { checkIsLoggedIn } from "utils/checkIsLoggedIn";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";

const GetQrList = (props) => {
    const [qrList, setQrList] = useState([]);
    // const [isLoggedIn, setLoginStatus] = useState(false);
    const [submitedChanges, setSubmitedChanges] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({});
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const WEB_URL = process.env.REACT_APP_WEB_ADDRESS;
    const UI_URL = process.env.REACT_APP_UI_ADDRESS;
    const user = JSON.parse(localStorage.getItem("user"));

    const [newqrListLenght, setqrListLenght] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = useMemo(() => qrList.slice(indexOfFirstRecord, indexOfLastRecord), [currentPage, qrList]);

    const getList = async (user) => {
        const response = await Axios.get(`${BASE_URL}/getQrListbyUserId/${user.id}`);
        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            const qrListWithImage = await getQrListWithImage(response.data);
            setQrList(qrListWithImage);
            setqrListLenght(Math.ceil(response.data.length / recordsPerPage));
            return response;
        }
    };

    // eslint-disable-next-line
    const generateCode = (url) => new Promise((resolve, reject) => {
        QRCode.toDataURL(url, {
            width: 300,
            margin: 2,
            color: {
                dark: "#635551",
                light: "#EEEEFF"
            },
            errorCorrectionLevel: "M"
        }, (err, url) => {
            if (err)
                return console.error(err);
            resolve(url);
        });
    });

    const getQrListWithImage = async (qrList) => {
        const qrListWithImage = qrList.map(async (qr) => {
            const image = await generateCode(`${WEB_URL}${UI_URL}?qr=${qr.qrCodeId}/${qr.idUnitate}`);
            return {
                ...qr,
                image
            };
        });
        return Promise.all(qrListWithImage);
    };

    useEffect(() => {
        getList(user);
    }, [props.onChange, submitedChanges, props.submitedChanges2]);

    console.log("currentRecords", currentRecords);

    return (
        <>
            <div className="" style={{ paddingTop: 20 }} >
                <h1 className="text-center">
                    <FormattedMessage
                        id="qrList.title"
                        defaultMessage="Lista camerelor si codurilor QR"
                    />
                </h1>
            </div>
            <div className="table-responsive" style={{ paddingTop: 20, maxWidth: 600, marginLeft: "auto", marginRight: "auto" }}>
                {qrList.length !== 0 && (
                    <>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col" style={{ width: 30 }}>
                                        #
                                    </th>
                                    <th scope="col">
                                        <FormattedMessage id="qrList.denumire" defaultMessage="Camera" />
                                    </th>
                                    <th scope="col">
                                        <FormattedMessage id="qrList.hotelName" defaultMessage="Hotel" />
                                    </th>
                                    <th scope="col">
                                        <FormattedMessage id="qrList.codQr" defaultMessage="cod Qr" />
                                    </th>
                                    <th scope="col" className="thButtonSpace">
                                        <FormattedMessage id="qrList.edit" defaultMessage="editeaza" />
                                    </th>
                                    <th scope="col" className="thButtonSpace">
                                        <FormattedMessage id="qrList.delete" defaultMessage="sterge" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRecords.map((val, key) => {
                                    return (
                                        <tr key={key} style={{ verticalAlign: "middle" }}>
                                            <th scope="row">{key + 1}</th>
                                            <td>{val.roomName}</td>
                                            <td>{val.hotelName}</td>
                                            <td style={{ width: 60 }}>
                                                <OpenImageButton
                                                    image={val.image}
                                                    qrName={val.roomName}
                                                    hotelName={val.hotelName}
                                                />
                                            </td>
                                            <td style={{ width: 60 }}>
                                                <EditQrButton
                                                    // id={val.id}
                                                    cameraId={val.qrCodeId}
                                                    camera={val.roomName}
                                                    idHotel={val.idUnitate}
                                                    hotelList={props.hotelList}
                                                    onSubmitedChanges={setSubmitedChanges}
                                                />
                                            </td>

                                            <td style={{ width: 60 }}>
                                                <DeleteQrButton
                                                    cameraId={val.qrCodeId}
                                                    camera={val.roomName}
                                                    hotelName={val.hotelName}
                                                    onSubmitedChanges={setSubmitedChanges}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        {errors.response && <div className="alert alert-danger" role="alert">{errors.response}</div>}

                        <MyPagination
                            page={currentPage}
                            pages={newqrListLenght}
                            count={newqrListLenght}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </>
                )}

                {qrList.length === 0 && (
                    <div className="alert alert-danger" role="alert">
                        <FormattedMessage id="qrList.noService" defaultMessage="Nu aveti coduri QR adaugate!" />
                    </div>
                )}
            </div>
        </>
    );
};

export default GetQrList;