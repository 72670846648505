import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";
import { FormattedMessage } from "react-intl";

import { Box, IconButton, Typography } from "@mui/material";
import { Send } from "@mui/icons-material"; // Poți folosi un alt icon dacă dorești
import "bootstrap-icons/font/bootstrap-icons.css";

export const SentRequestButton = (props) => {
    // console.log("propsSEND Request", props);
    const [showModalMesajConfirmare, setShowModalMesajConfirmare] = useState(false);
    const [showModalMesajConfirmareTrimitere, setShowModalMesajConfirmareTrimitere] = useState(false);
    const [showModalMesajEroare, setShowModalMesajEroare] = useState(false);
    const [stareSolicitare, setStareSolicitare] = useState("waiting");
    const [submitting, setSubmitting] = useState(false);
    // const [intervalBetweenRequests, setIntervalBetweenRequests] = useState(0);

    //eslint-disable-next-line
    const [errors, setErrors] = useState(null);

    const handleCloseModalMesajConfirmare = () => setShowModalMesajConfirmare(false);
    const handleShowModalMesajConfirmare = () => setShowModalMesajConfirmare(true);
    const handleCloseModalMesajConfirmareTrimitere = () => {
        setShowModalMesajConfirmareTrimitere(false);
        setShowModalMesajConfirmare(false);
        setStareSolicitare("exist");
        setSubmitting(false);
    };
    const handleCloseModalMesajEroare = () => setShowModalMesajEroare(false);
    const handleShowModalMesajEroare = () => setShowModalMesajEroare(true);

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    // const solicitariPer24Hours = props.solicitariPer24Hours.toFixed(2);
    // const intervalBetweenRequests = isNaN(solicitariPer24Hours) || 0 >= solicitariPer24Hours
    //     ? 0
    //     : 24 / solicitariPer24Hours;

    const verifyIfRequestExists = async () => {
        const data = {
            servId: props.servId,
            hotelId: props.hotelId,
            roomId: props.roomId,
        };

        const resp = await Axios.post(`${BASE_URL}/verifyRequest`, data);
        // console.log({ "Response verifyRequest": resp.data });
        // console.log({ "Response verifyRequest length": resp.data.length });

        // const dateTime = new Date().toISOString().slice(0, 19).replace("T", " ");
        // const dateOfRequest = resp.data[0].created_at;

        // console.log({ "dateTime": dateTime });
        // console.log({ "dateOfRequest": dateOfRequest });

        if (resp.data.length > 0) // daca exista deja o solicitare pentru acest serviciu
        {
            // alert('Nu mai poti trimite solicitari pentru aceasta camera');
            setStareSolicitare("exist");
            setShowModalMesajConfirmare(false);
            setShowModalMesajConfirmareTrimitere(false);
            setShowModalMesajEroare(true);
            setSubmitting(false);
        }
        else {
            setStareSolicitare("notExist");
            setShowModalMesajConfirmare(true);
            setShowModalMesajConfirmareTrimitere(true);
            setShowModalMesajEroare(false);
            setSubmitting(true);
            finishSubmit();
        }
    };

    const finishSubmit = async () => {
        const data = {
            servId: props.servId,
            hotelId: props.hotelId,
            roomId: props.roomId,
        };

        await Axios.post(`${BASE_URL}/sentRequest`, data).then((res) => {
            if (res.status === 200) {
                // console.log({'Succesfully sent request': res.status});
                setErrors(null);

            } else {
                // console.log({'Failed to send request': res.status});
                setErrors(res.data.message);
            }
        });
    };

    const handleSubmit = async (p) => {
        p.preventDefault();
        await verifyIfRequestExists();
    };

    return (
        <>
            {/* <div
            className="d-flex justify-content-center"
            onClick={
                (stareSolicitare === "waiting" && !submitting)
                  ? handleShowModalMesajConfirmare
                  : (stareSolicitare === "exist" && !submitting)
                    ? handleShowModalMesajEroare
                    : null
              }
        >
                <i className="bi bi-send-plus" style={{paddingRight:10, paddingLeft:15}}></i>{" "}{props.serviceName}
            </div> */}

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    cursor: "pointer",
                    backgroundColor: "transparent", // Fundal transparent
                    padding: "8px 16px", // Un padding mai mic
                    borderRadius: "20px", // Colțuri rotunjite moderate
                    width: "100%", // Ocupă întreaga lățime
                    transition: "background-color 0.3s ease", // Tranizație lină
                    "&:hover": {
                        backgroundColor: "#f5f5f5", // Fundal gri deschis la hover
                    },
                }}
                onClick={
                    (stareSolicitare === "waiting" && !submitting)
                        ? handleShowModalMesajConfirmare
                        : (stareSolicitare === "exist" && !submitting)
                            ? handleShowModalMesajEroare
                            : null
                }
            >
                <IconButton
                    sx={{
                        paddingRight: 1,
                        paddingLeft: 1,
                        color: "inherit", // Culoarea iconiței preluată de la elementul părinte
                    }}
                >
                    <Send fontSize="medium" />
                </IconButton>
                <Typography
                    variant="body1"
                    sx={{
                        color: "#333", // Culoare text subtilă
                        fontWeight: "500", // Font mediu pentru a fi mai subtil
                        marginLeft: 1, // Un mic spațiu între icon și text
                    }}
                >
                    {props.serviceName}
                </Typography>
            </Box>

            {(stareSolicitare === "waiting" && !submitting) && (
                <Modal show={showModalMesajConfirmare}>
                    <Form onSubmit={handleSubmit}>
                        <Modal.Header >
                            <Modal.Title>
                                <div className="d-flex justify-content-center">
                                    <i className="bi bi-send-plus" style={{ color: "#ff5825", fontSize: 45 }}></i><br />
                                </div>
                                <FormattedMessage id="requests.sendRequest" defaultMessage={"Ești sigur că vrei sa trimiti solicitarea referitoare la "} />{" "}{props.serviceName}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleCloseModalMesajConfirmare}>
                                <FormattedMessage id="requests.no" defaultMessage={"Nu"} />
                            </Button>
                            <Button variant="success" onClick={handleCloseModalMesajConfirmare} type="submit">
                                <FormattedMessage id="requests.yes" defaultMessage={"Da"} />
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )}
            {(stareSolicitare === "notExist" && showModalMesajConfirmareTrimitere) && (
                <Modal show={submitting} >
                    <Modal.Header >
                        <Modal.Title>
                            <div className="d-flex justify-content-center">
                                <i className="bi bi-check-circle" style={{ color: "#ff5825", fontSize: 45 }}></i><br />
                            </div>
                            <FormattedMessage id="requests.successMessage" defaultMessage={"Solicitarea a fost trimisă cu succes!"} />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalMesajConfirmareTrimitere}>
                            <FormattedMessage id="requests.ok" defaultMessage={"Ok"} />
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {(stareSolicitare === "exist" && !submitting) && (
                <Modal show={showModalMesajEroare}>
                    <Modal.Header >
                        <Modal.Title>
                            <div className="d-flex justify-content-center">
                                <i className="bi bi-exclamation-circle" style={{ color: "#ff5825", fontSize: 45 }}></i><br />
                            </div>
                            <FormattedMessage id="requests.errorMessage" defaultMessage={"Exista deja o solicitare pentru acest serviciu!"} />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalMesajEroare}>
                            <FormattedMessage id="requests.ok" defaultMessage={"Ok"} />
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};