import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Axios from "axios";
import { FormattedMessage } from "react-intl";

const DeleteReviewButton = (props) => {

  const [show, setShow] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (p) => {
    p.preventDefault();
    setSubmitting(true);
  };

  const finishSubmit = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const data = {
      idReview: props.idReview
    };

    try {
      const res = await Axios.post(`${BASE_URL}/deleteReview`, data);
      console.log(res);
      props.onChange();
      setSubmitting(false);
      handleClose();
    } catch (error) {
      console.log("Eroare la ștergerea recenziei:", error);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (submitting) {
      finishSubmit();
    }
  }, [submitting]);

  return (
    <div>
      <Button
        variant="danger"
        onClick={handleShow}
      >
        <i className="bi bi-trash3"></i>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title><FormattedMessage id="delReview.deleteReview" defaultMessage="Sterge adresa?" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormattedMessage id="delReview.delConfirm" defaultMessage="Esti sigur ca vrei sa stergi adresa?" /><b>{props.review}</b>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="delReview.close" defaultMessage="Inchide" />
            </Button>
            <Button variant="danger" type="submit">
              <FormattedMessage id="delReview.delete" defaultMessage="Sterge" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default DeleteReviewButton;