import React, { useState } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Form, InputGroup } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const AddReviewForm = (props) => {
  const [inputFields, setinputFields] = useState({
    denumire: "",
    url: "",
    idUnitate: "0",
  });
  const [errors, setErrors] = useState({});
  const [showButton, setShowButton] = useState(true);
  const [message, setMessage] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.denumire) {
      errors.denumire = <FormattedMessage id="addReview.addNameErr" defaultMessage="Adauga denumirea paginii de socializare" />;
    }
    if (!inputValues.url) {
      errors.url = <FormattedMessage id="addReview.addUrlErr" defaultMessage="Adauga adresa url a paginii de socializare" />;
    }
    if (inputValues.idUnitate === "0" || !inputValues.idUnitate) {
      errors.idUnitate = <FormattedMessage id="addReview.selectHotelErr" defaultMessage="Alege hotelul" />;
    }
    return errors;
  };

  const resetForm = () => {
    setinputFields({
      denumire: "",
      url: "",
      idUnitate: "0",
    });
    setErrors({});
  };

  const handleChange = (event) => {
    setinputFields({
      ...inputFields,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validateValues(inputFields);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      const data = {
        denumire: inputFields.denumire,
        url: inputFields.url,
        idUnitate: inputFields.idUnitate,
        userId: user.id,
      };
      Axios.post(`${BASE_URL}/addReview`, data)
        .then((response) => {
          if (response.data.error) {
            setErrors({ response: response.data.error });
          } else {
            props.onChange();
            resetForm();
            setShowButton(false);
            const textMess = "<i class='bi bi-check2-circle h2'></i> Adresa a fost adaugata cu succes!";
            setMessage(textMess);
            setTimeout(() => {
              setMessage("");
              setShowButton(true);
            }, 3000);
          }
        })
        .catch((error) => {
          setErrors({ response: error.message });
        });
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Form onSubmit={handleSubmit}>
        <InputGroup style={{ marginTop: 10 }}>
          <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
            <FormattedMessage id="addReview.denumire" defaultMessage="Denumire" />
          </InputGroup.Text>
          <Form.Control
            type="text"
            name="denumire"
            value={inputFields.denumire}
            onChange={handleChange}
            className="inputLabel"
            placeholder="ex. Facebook"
          />
        </InputGroup>
        {errors.denumire && <p className="error">{errors.denumire}</p>}

        <InputGroup style={{ marginTop: 10 }}>
          <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
            <FormattedMessage id="addReview.url" defaultMessage="URL" />
          </InputGroup.Text>
          <Form.Control
            type="text"
            name="url"
            value={inputFields.url}
            onChange={handleChange}
            className="inputLabel"
            placeholder="ex. https://facebook.com/..."
          />
        </InputGroup>
        {errors.url && <p className="error">{errors.url}</p>}

        <InputGroup style={{ marginTop: 10 }}>
          <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
            <FormattedMessage id="addReview.idUnitate" defaultMessage="Hotelul" />
          </InputGroup.Text>
          <Form.Select
            name="idUnitate"
            value={inputFields.idUnitate}
            onChange={handleChange}
          >
            <option value="0"><FormattedMessage id="addReview.selectHotel" defaultMessage="Alege hotelul" /></option>
            {props.hotelsList.map((hotel) => (
              <option key={hotel.idUnitate} value={hotel.idUnitate}>{hotel.denumire}</option>
            ))}
          </Form.Select>
        </InputGroup>
        {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
        {showButton && (
          <button
            type="submit"
            style={{ marginTop: 10 }}
            className="universalButton buttonUniv btn-rounded waves-effect waves-light"
          >
            <FormattedMessage id="addReview.save" defaultMessage="Salveaza" />
          </button>
        )}
        {message && (
          <p style={{ color: "#32af00", display: "flex", flexDirection: "row", alignItems: "center" }} dangerouslySetInnerHTML={{ __html: message }} />
        )}
      </Form>
    </div>
  );
};

export default AddReviewForm;