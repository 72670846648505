// ComplaintQueriesLogic.jsx
import { Component } from "react";
import axios from "axios";

class ComplaintQueriesLogic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalComplaints: null,
      activeComplaints: null,
      inProgressComplaints: null,
      resolvedComplaints: null,
      loading: false,
      error: null,
    };

    this.BASE_URL = process.env.REACT_APP_BASE_URL;
  }

  // Exemplu: o metodă care preia toate datele de sesizări
  fetchComplaintsData = async (dataStart, dataEnd, hotelId) => {
    this.setState({ loading: true, error: null });
    try {
      // Presupunem că pe backend ai endpoint-uri separate pentru fiecare tip de informație:
      const totalRes = await axios.get(`${this.BASE_URL}/sesizari/total`, {
        params: { dataStart, dataEnd, hotelId },
      });
      const activeRes = await axios.get(`${this.BASE_URL}/sesizari/active`, {
        params: { dataStart, dataEnd, hotelId },
      });
      const inProgressRes = await axios.get(`${this.BASE_URL}/sesizari/inprogress`, {
        params: { dataStart, dataEnd, hotelId },
      });
      const resolvedRes = await axios.get(`${this.BASE_URL}/sesizari/resolved`, {
        params: { dataStart, dataEnd, hotelId },
      });

      this.setState({
        totalComplaints: totalRes.data[0].total,
        activeComplaints: activeRes.data[0].active,
        inProgressComplaints: inProgressRes.data[0].inProgress,
        resolvedComplaints: resolvedRes.data[0].resolved,
        loading: false,
      });
    } catch (error) {
      console.error("Eroare la preluarea datelor de sesizări:", error);
      this.setState({ error, loading: false });
    }
  };

  componentDidMount() {
    // Exemplu: apelăm metoda cu niște parametri default
    this.fetchComplaintsData("2025-01-01", "2025-01-31", 30, 4);
  }

  render() {
    // Folosim render props pentru a expune datele și metoda de refetch către componenta părinte
    return this.props.children({
      totalComplaints: this.state.totalComplaints,
      activeComplaints: this.state.activeComplaints,
      inProgressComplaints: this.state.inProgressComplaints,
      resolvedComplaints: this.state.resolvedComplaints,
      loading: this.state.loading,
      error: this.state.error,
      fetchComplaintsData: this.fetchComplaintsData,
    });
  }
}

export default ComplaintQueriesLogic;
