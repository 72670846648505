import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import { Drawer, List, ListItem, ListItemText, ListItemButton, CssBaseline, Box, Toolbar, Typography, Collapse, IconButton, useTheme, useMediaQuery } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu"; // Icon pentru butonul hamburger
import CloseIcon from "@mui/icons-material/Close"; // Icon pentru a închide meniul
import logoNou from "../images/logoNouRotundMaro.png";

const drawerWidth = 240;

const Menu = ({ children }) => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detectăm dispozitivele mobile

    const handleLogout = () => {
        localStorage.removeItem("user");
        setUser(null);
        navigate("/login");
    };

    // State pentru deschiderea submeniurilor
    const [openMenu, setOpenMenu] = useState(null);

    // State pentru deschiderea Drawer-ului pe mobil
    const [drawerOpen, setDrawerOpen] = useState(false);

    // Funcție pentru a deschide sau închide submeniurile
    const handleClick = (key) => {
        setOpenMenu(openMenu === key ? null : key);
    };

    // Meniul de bază
    const baseMenu = [
        { key: 1, name: "Acasa", path: "/" },
    ];

    // Meniul pentru utilizatori simpli
    const userMenu = [
        { key: 2, name: "Panou de control", path: "/members/dashboard" },
        {
            key: 3, name: "Contul meu", items: [
                ...(user && (user.role === 0 || user.role === 1) ? [{ key: 4, name: "Financiar", path: "/members/financial" }] : []),
                { key: 5, name: "Setari", path: "/members/settings" }
            ]
        },
    ];

    // Meniul pentru admini și superadmini
    const adminMenu = [
        {
            key: 8, name: "Configurare", items: [
                { key: 9, name: "Hoteluri", path: "/members/addNewHotel" },
                { key: 10, name: "Departamente", path: "/members/addNewDepartament" },
                { key: 11, name: "Utilizatori", path: "/members/addNewUser" },
                { key: 12, name: "Servicii", path: "/members/addServices" },
                { key: 13, name: "Camere", path: "/members/createQrCode" },
                { key: 14, name: "Optiuni cu plata", path: "/members/payedOptions" }
            ]
        },
        {
            key: 15, name: "Optionale", items: [
                { key: 16, name: "Retele Wi-Fi", path: "/members/wifi" },
                { key: 17, name: "Recenzii", path: "/members/reviews" },
                { key: 18, name: "Check-in/out", path: "/members/checkInOut" },
                { key: 19, name: "Evenimente", path: "/members/events" },
                { key: 20, name: "Restaurant", path: "/members/restaurant" }
            ]
        }
    ];

    // Meniul final combinat
    let fullMenu = [
        ...baseMenu,
        ...(user && (user.role === 0 || user.role === 1 || user.role === 2) ? userMenu : []),
        ...(user && (user.role === 0 || user.role === 1) ? adminMenu : []),
        { key: 6, name: "Despre", path: "/about" },
        { key: 7, name: "Contact", path: "/contact" },
        ...(user ? [
            { key: 21, name: "Deconectare", path: "#", onClick: handleLogout }
        ] : [
            { key: 22, name: "Autentificare", path: "/login" }
        ])
    ];

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <Drawer
                variant={isMobile ? "temporary" : "permanent"} // Dacă este mobil, folosim Drawer temporar
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)} // Închidem meniul când este pe mobil
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    ["& .MuiDrawer-paper"]: { width: drawerWidth, boxSizing: "border-box" },
                }}
            >
                {isMobile && (
                    <>
                        <Toolbar sx={{ display: "flex", alignItems: "center", mt: "-8px" }}>
                            <img src={logoNou} alt="Logo" width={35} height={35} />
                            <Typography variant="h6" sx={{ ml: 1 }}>qrAlert.ro</Typography>
                        </Toolbar>
                        <Box sx={{ width: "100%", borderBottom: "1px solid gray", mt: "-10px", padding: "1px" }} />
                    </>
                )}
                {!isMobile && (
                    <>
                        <Toolbar sx={{ display: "flex", alignItems: "center", mt: "-12px" }}>
                            <img src={logoNou} alt="Logo" width={35} height={35} />
                            <Typography variant="h6" sx={{ ml: 1 }}>qrAlert.ro</Typography>
                        </Toolbar>
                        <Box sx={{ width: "100%", borderBottom: "1px solid gray", mt: "-12px" }} />
                    </>
                )}

                <List>
                    {fullMenu.map((item) => (
                        <div key={item.key}>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => item.items ? handleClick(item.key) : item.onClick ? item.onClick() : navigate(item.path)}>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                            {item.items && (
                                <Collapse in={openMenu === item.key} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.items.map((subItem) => (
                                            <ListItem key={subItem.key} disablePadding>
                                                <ListItemButton sx={{ pl: 4 }} onClick={() => navigate(subItem.path)}>
                                                    <ListItemText primary={subItem.name} />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            )}
                        </div>
                    ))}
                </List>
            </Drawer>

            {/* Buton pentru mobil */}
            {isMobile && (
                <IconButton
                    sx={{ position: "absolute", top: 45, left: 16 }}
                    onClick={() => setDrawerOpen(true)}
                >
                    <MenuIcon />
                </IconButton>
            )}

            {/* Buton pentru a închide Drawer-ul pe mobil */}
            {isMobile && drawerOpen && (
                <IconButton
                    sx={{ position: "absolute", top: 16, right: 16 }}
                    onClick={() => setDrawerOpen(false)}
                >
                    <CloseIcon />
                </IconButton>
            )}

            <Box component="main" sx={{ flexGrow: 1, p: 3, ml: isMobile ? 0 : `${drawerWidth}px` }}>
                {children}
            </Box>
        </Box>
    );
};

export default Menu;
