import React, { useState } from "react";
import { Box, Typography, Grid2, Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { LocationOn, Phone } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { SentRequestButton } from "./modal/sentRequest";

const HotelInfo = ({ roomId, hotelData, cityName, countyName, departments }) => {

  const [expandedAccordion, setExpandedAccordion] = useState(null); // Stare pentru acordionul activ

  const handleAccordionToggle = (panelIndex) => {
    setExpandedAccordion(expandedAccordion === panelIndex ? null : panelIndex); // Comută starea
  };

  return (
    <Box className="uiContainer">
      <Box className="uiContent">
        {hotelData?.map((val, key) => (
          <Box key={key} sx={{ marginBottom: 4 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 2, marginTop: 3 }}>
              {/* <img src={logo} alt="Logo" style={{ width: 50, marginRight: 8 }} /> */}
              <Typography variant="h4" textAlign={"center"}>{val.denumire}</Typography>
            </Box>

            {(val.name || val.telefon || val.email) && (
              <>
                <Grid2 container spacing={1} sx={{ marginBottom: 3, marginTop: 4, alignItems: "center", justifyContent: "center", }}>
                  <Grid2 item>
                    <Typography variant="body1">
                      <LocationOn fontSize="16px" sx={{ marginRight: "3px" }} />
                      <span>{val.adresa}</span>,{" "}
                      {cityName?.map((city, index) => (
                        <span key={index}>{city.name}</span>
                      ))}
                      ,{" "}
                      {countyName?.map((county, index) => (
                        <span key={index}>{county.name}</span>
                      ))}
                    </Typography>
                  </Grid2>
                </Grid2>

                <Grid2 container spacing={1} sx={{ marginBottom: 3, alignItems: "center", justifyContent: "center" }}>
                  <Grid2 item>
                    <Typography variant="body1">
                      <Phone fontSize="14px" sx={{ marginRight: "3px" }} />
                      {val.telefon}
                    </Typography>
                  </Grid2>
                </Grid2>
              </>
            )}
          </Box>
        ))}
      </Box>

      <Box sx={{ marginTop: 1 }}>
        {departments && Object.keys(departments).length > 0 && (
          <>
            <Typography variant="h6" sx={{ marginBottom: 2, textAlign: "center" }}>
              Pentru a face o solicitare alege departamentul corespunzător.
            </Typography>
            {Object.keys(departments).map((depName, index) => (
              <Accordion
                key={index}
                expanded={expandedAccordion === index}
                onChange={() => handleAccordionToggle(index)}
              >
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography variant="subtitle1">{depName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {departments[depName].map((servName, subIndex) => {
                    const [servId, serviceName] = servName.split("/");
                    return (
                      serviceName !== "null" && (
                        <Box
                          key={subIndex}
                          sx={{
                            marginBottom: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <SentRequestButton
                            servId={servId}
                            serviceName={serviceName}
                            hotelId={hotelData[0].idUnitate}
                            roomId={roomId}
                          // solicitariPer24Hours={solicitariPer24Hours}
                          />
                        </Box>
                      )
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            ))}
          </>
        )}
      </Box>
    </Box >
  );
};

export default HotelInfo;