import React from "react";

import { FormattedMessage } from "react-intl";
import { DeleteWifiButton } from "./modal/delete";
import { EditWifiButton } from "./modal/edit";
import { Table } from "react-bootstrap";

const WifiList = ({ wifiList, hotelsList, handleState }) => {
    console.log("hotelsList LIST", hotelsList);

    return (
        <div>
            <div className="" style={{ paddingTop: 20, paddingBottom: 20 }} >
                <h1 className="text-center">
                    <FormattedMessage
                        id="wifiList.title"
                        defaultMessage="Lista retelelor Wi-Fi"
                    />
                </h1>
            </div>
            <Table responsive style={{ paddingTop: 20, maxWidth: 700, marginLeft: "auto", marginRight: "auto" }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th><FormattedMessage id="wifiList.network" defaultMessage="Nume retea" /></th>
                        <th><FormattedMessage id="wifiList.security" defaultMessage="Securitate" /></th>
                        <th><FormattedMessage id="wifiList.password" defaultMessage="Parola" /></th>
                        <th><FormattedMessage id="wifiList.hotel" defaultMessage="Hotel" /></th>
                        <th><FormattedMessage id="wifiList.edit" defaultMessage="Editeaza" /></th>
                        <th><FormattedMessage id="wifiList.delete" defaultMessage="Sterge" /></th>
                    </tr>
                </thead>
                <tbody>
                    {wifiList.map((wifi, index) => {
                        return (
                            <tr key={wifi.idWifi}>
                                <td>{index + 1}</td>
                                <td>{wifi.network}</td>
                                <td>{wifi.security}</td>
                                <td>{wifi.password}</td>
                                <td>{wifi.hotel}</td>
                                <td>
                                    <EditWifiButton
                                        idWifi={wifi.idWifi}
                                        network={wifi.network}
                                        security={wifi.security}
                                        password={wifi.password}
                                        hotelName={wifi.hotel}
                                        idHotel={wifi.idHotel}
                                        hotelsList={hotelsList}
                                        onChange={handleState}
                                    />
                                </td>
                                <td>
                                    <DeleteWifiButton
                                        idWifi={wifi.idWifi}
                                        network={wifi.network}
                                        hotelName={wifi.hotel}
                                        onChange={handleState}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default WifiList;