import React, { useState } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Form } from "react-bootstrap";

import createTimeLocalFormat from "../../../utils/createTimeLocalFormat";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

export const EditWifiButton = (props) => {
  console.log("propsEdit", props);

  const [inputFields, setInputFields] = useState({
    checkIn: createTimeLocalFormat(props.checkIn),
    checkOut: createTimeLocalFormat(props.checkOut),
    hotel: props.idHotel
  });
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputFields({
      ...inputFields,
      [name]: value
    });
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const dataToSend = {
      checkIn: inputFields.checkIn,
      checkOut: inputFields.checkOut,
      idUnitate: inputFields.hotel,
      userId: user.id
    };

    const response = await Axios.post(`${BASE_URL}/editCheckInOut`, dataToSend);
    if (response.data.error) {
      setErrors({ response: response.data.error });
    } else {
      props.onChanges(counter => counter + 1);
      handleClose();
      return response;
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
      >
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleEdit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="editcheckInOut.title" defaultMessage="Editeaza orele pentru checkIn/CheckOut " />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="editcheckInOut.checkIn" defaultMessage="checkIn" />
              </Form.Label>
              <Form.Control
                type="time"
                placeholder="checkIn"
                name="checkIn"
                value={inputFields.checkIn}
                onChange={handleInputChange}
              />
            </Form.Group>
            {errors.checkIn && <p className="error">{errors.checkIn}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="editcheckInOut.checkOut" defaultMessage="checkOut" />
              </Form.Label>
              <Form.Control
                type="time"
                placeholder="checkOut"
                name="checkOut"
                value={inputFields.checkOut}
                onChange={handleInputChange}
              />
            </Form.Group>
            {errors.checkOut && <p className="error">{errors.checkOut}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="editcheckInOut.hotel" defaultMessage="Hotel" />
              </Form.Label>
              <Form.Control
                as="select"
                name="hotel"
                value={inputFields.hotel}
                onChange={handleInputChange}
              >
                {Array.isArray(props.hotelsList) && props.hotelsList.map((hotel, key) => (
                  <option key={key} value={hotel.idUnitate}>{hotel.denumire}</option>
                ))}
              </Form.Control>
            </Form.Group>
            {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="editcheckInOut.close" defaultMessage="Inchide" />
            </Button>
            <Button variant="success" onClick={handleClose} type="submit">
              <FormattedMessage id="editcheckInOut.save" defaultMessage="Salveaza" />
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
