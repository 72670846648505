import React from "react";
import { FormattedMessage } from "react-intl";
// import { Form, InputGroup } from "react-bootstrap";
import { EditEventButton } from "./modal/edit";
import { DeleteEventButton } from "./modal/delete";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const EventsList = (props) => {
    console.log({ "list props:": props.events });
    function formatDate(inputDate) {
        // Crează un obiect Date din string-ul UTC
        const date = new Date(inputDate);

        // Creează o listă cu numele lunilor în română
        const months = [
            "ianuarie", "februarie", "martie", "aprilie", "mai", "iunie",
            "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"
        ];

        // Extrage componentele datei
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        let hours = date.getHours();
        let minutes = date.getMinutes();

        // Adaugă un zero în fața minutei dacă este sub 10
        minutes = minutes < 10 ? "0" + minutes : minutes;

        // Formatează ora pentru a fi 24h
        hours = hours < 10 ? "0" + hours : hours;

        // Construiește șirul formatat
        const formattedDate = `${day} ${months[monthIndex]} ${year} ${hours}:${minutes}`;

        return formattedDate;
    }

    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="">
                <h2><FormattedMessage id="events.title" defaultMessage="Evenimente" /></h2>
                <div className="table-responsive" style={{ paddingTop: 20 }} >
                    {props.events.length != 0 && <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col"><FormattedMessage id="events.eventName" defaultMessage="Denumirea" /></th>
                                <th scope="col"><FormattedMessage id="events.describer" defaultMessage="Descrierea" /></th>
                                <th scope="col"><FormattedMessage id="events.dateTime" defaultMessage="Data" /></th>
                                <th scope="col"><FormattedMessage id="events.hotel" defaultMessage="Hotelul" /></th>
                                <th scope="col" className="thButtonSpace"><FormattedMessage id="events.edit" defaultMessage="Edit" /></th>
                                <th scope="col" className="thButtonSpace"><FormattedMessage id="events.delete" defaultMessage="Sterge" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.events.map((val, key) => {
                                return (
                                    <tr key={val.eventId}>
                                        <td>{key + 1}</td>
                                        <td>{val.eventName}</td>
                                        <td style={{ textAlign: "justify" }}>{val.eventDetails}</td>
                                        <td>{formatDate(val.dateOfEvent)}</td>
                                        <td>{val.hotel}</td>
                                        <td className="thButtonSpace">
                                            <EditEventButton
                                                {...props}
                                                eventId={val.eventId}
                                                eventName={val.eventName}
                                                eventDescriber={val.eventDetails}
                                                eventDateTime={val.dateOfEvent}
                                                eventIdUnitate={val.hotel}
                                                onChanges={props.onChange}
                                            />
                                        </td>
                                        <td className="thButtonSpace">
                                            {/* {val.role === 1 && */}
                                            {/* <DeleteUserButton
                                            user={val.username}
                                            userId={val.id}
                                            hotel={val.unitName}
                                            onChange={setSubmitedChanges}
                                        /> */}
                                            <DeleteEventButton
                                                eventId={val.eventId}
                                                eventName={val.eventName}
                                                onChanges={props.onChange}
                                            />
                                        </td>
                                    </tr>
                                );
                            }
                            )}
                        </tbody>
                    </table>
                    }
                    {props.events.length == 0 && <div className="alert alert-danger" role="alert">
                        <FormattedMessage id="events.noEvents" defaultMessage="Nu exista evenimente" />
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default EventsList;
