import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import Axios from "axios";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import EventForm from "./eventsForm";
import EventsList from "./eventsList";
import { FormattedMessage } from "react-intl";
import "./index.css";
import "../../App.css";

const Events = () => {
  const [counter, setCounter] = useState(0);
  const [hotelList, setHotelList] = useState([]);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  if (!user) {
    navigate("/login");
    return null;
  }

  // const user = JSON.parse(localStorage.getItem("user"));
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const getHotelList = async () => {
    // setLoadingHotels(true);
    try {
      const response = await Axios.post(`${BASE_URL}/getHotelList`, { userId: user.id });
      if (response.data.error) {
        console.error(response.data.error);
      } else {
        setHotelList(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch hotel list", error);
    } finally {
      // setLoadingHotels(false);
    }
  };

  const getEventsList = async () => {
    try {
      const response = await Axios.post(`${BASE_URL}/getEventsList`, { userId: user.id });
      if (response.data.error) {
        console.error(response.data.error);
      } else {
        setEvents(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch events", error);
    }
  };

  useEffect(() => {
    getHotelList();
    getEventsList();
  }, [counter, user.id]); // Reload on changes

  return (
    <div>
      <TopMenu />
      <Menu />
      <div className="firstEventsContainer">
        <div className="container" data-aos="fade-in">
          <h2>
            <FormattedMessage id="events.mainTitle" defaultMessage="Pagina de evenimente" />
          </h2>
          <h6>
            <FormattedMessage id="events.subtitle" defaultMessage="adauga evenimentele tale" />
          </h6>
        </div>
      </div>
      <div className="containerEventsForm">
        <EventForm
          onChange={() => setCounter(counter + 1)}
          hotelsList={hotelList}
        />
        <hr />
        <EventsList
          onChange={() => setCounter(counter + 1)}
          hotelsList={hotelList}
          events={events}
        />

      </div>
      <Footer />
    </div>
  );
};

export default Events;