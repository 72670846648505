import React from "react";
import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import AboutPage from "./AboutPage";

const ParentAboutPage = () => {
  return (
    <div>
      <TopMenu />
      <Menu />
      <AboutPage />
      <Footer />
    </div>
  );
};

export default ParentAboutPage;