import React from "react";
import { Container, Typography, Box, Grid, Paper, Button } from "@mui/material";
import { styled } from "@mui/system";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Crearea unei teme globale pentru aplicație
const theme = createTheme({
  palette: {
    primary: {
      main: "#FF8A00",
    },
    secondary: {
      main: "#E52B50",
    },
    background: {
      paper: "#fff",
    },
    text: {
      primary: "#000",
      secondary: "#555",
    },
    error: {
      main: "#d32f2f",
    },
  },
});

// Stiluri personalizate
const SectionHeader = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  fontWeight: 600,
  color: theme.palette.primary.main,
  textAlign: "center",
  marginBottom: "1rem",
}));

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: "1.1rem",
  color: theme.palette.text.secondary,
  textAlign: "center",
  marginBottom: "2rem",
}));

const FeatureCard = styled(Paper)(({ theme }) => ({
  padding: "20px",
  borderRadius: "10px",
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
}));

const AboutPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg" sx={{ marginTop: 4 }}>
        {/* Header */}
        <Box sx={{ background: "linear-gradient(90deg, #FF8A00, #E52B50)", padding: "40px 0", borderRadius: "10px" }}>
          <Typography variant="h3" sx={{ color: "white", textAlign: "center", fontWeight: "bold" }}>
            Despre QRAlerT
          </Typography>
          <SectionText>
            Lansat în ianuarie 2021, <strong>QRAlerT</strong> este o platformă inovativă dedicată unităților de cazare, creată pentru a
            îmbunătăți și eficientiza comunicarea între clienți și personalul acestora.
          </SectionText>
        </Box>

        {/* Ce oferim */}
        <Box sx={{ marginTop: "40px" }}>
          <SectionHeader variant="h4">Ce Oferim?</SectionHeader>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <FeatureCard>
                <Typography variant="h5" gutterBottom>
                  Comunicare rapidă și eficientă
                </Typography>
                <Typography paragraph>
                  Facilităm comunicarea directă între oaspeți și personal, eliminând întârzierile și creând un flux de informații
                  instantaneu.
                </Typography>
              </FeatureCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <FeatureCard>
                <Typography variant="h5" gutterBottom>
                  Ușor de utilizat
                </Typography>
                <Typography paragraph>
                  Platforma este intuitivă, adaptabilă și accesibilă oricărei persoane, indiferent de nivelul lor tehnologic.
                </Typography>
              </FeatureCard>
            </Grid>
          </Grid>
        </Box>

        {/* Misiunea Noastră */}
        <Box sx={{ marginTop: "40px", backgroundColor: "#F4F6F8", padding: "40px", borderRadius: "10px" }}>
          <SectionHeader variant="h4">Misiunea Noastră</SectionHeader>
          <SectionText>
            La <strong>QRAlerT</strong>, misiunea noastră este de a transforma experiența de cazare într-o experiență mai ușor de
            gestionat și mai plăcută pentru toți cei implicați. Creăm soluții care ajută unitățile de cazare și oaspeții lor să
            comunice mai rapid și eficient.
          </SectionText>
        </Box>

        {/* Avantaje */}
        <Box sx={{ marginTop: "40px" }}>
          <SectionHeader variant="h4">De ce să alegi QRAlerT?</SectionHeader>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <FeatureCard>
                <Typography variant="h6" gutterBottom>
                  Comoditate
                </Typography>
                <Typography paragraph>
                  Simplifică procesul de comunicare pentru personal și oaspeți, economisind timp și resurse.
                </Typography>
              </FeatureCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FeatureCard>
                <Typography variant="h6" gutterBottom>
                  Răspuns rapid
                </Typography>
                <Typography paragraph>
                  Oferă răspunsuri instantanee și posibilitatea de a rezolva rapid orice problemă.
                </Typography>
              </FeatureCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FeatureCard>
                <Typography variant="h6" gutterBottom>
                  Flexibilitate
                </Typography>
                <Typography paragraph>
                  Platforma poate fi personalizată în funcție de nevoile fiecărei unități de cazare.
                </Typography>
              </FeatureCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FeatureCard>
                <Typography variant="h6" gutterBottom>
                  Eficiență
                </Typography>
                <Typography paragraph>
                  Ajută unitățile de cazare să economisească timp și să îmbunătățească serviciile oferite oaspeților.
                </Typography>
              </FeatureCard>
            </Grid>
          </Grid>
        </Box>

        {/* Call to Action */}
        <Box sx={{ marginTop: "40px", textAlign: "center" }}>
          <Typography variant="h5" gutterBottom>
            Fii parte din comunitatea QRAlerT!
          </Typography>
          <Typography paragraph>
            Îți mulțumim că ai ales QRAlerT! Suntem aici pentru a îmbunătăți experiențele de cazare și pentru a face mai ușoară
            gestionarea cerințelor oaspeților.
          </Typography>
          <Button variant="contained" color="primary" size="large">
            Contactează-ne
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default AboutPage;
