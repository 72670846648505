import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
} from "@mui/material";

const rows = [
    {
        camera: "Camera 101",
        departament: "IT",
        sesizare: "Probleme rețea",
        urgent: "Da",
        plasare: "27/01/2025 10:30",
        rezolvare: "27/01/2025 14:00",
        rezolvatDe: "Ion Popescu",
    },
    {
        camera: "Camera 202",
        departament: "Administrație",
        sesizare: "Fără curent",
        urgent: "Nu",
        plasare: "27/01/2025 11:00",
        rezolvare: "28/01/2025 09:00",
        rezolvatDe: "Maria Ionescu",
    },
    {
        camera: "Camera 303",
        departament: "Financiar",
        sesizare: "Computer defect",
        urgent: "Da",
        plasare: "27/01/2025 12:00",
        rezolvare: "27/01/2025 15:30",
        rezolvatDe: "Andrei Marin",
    },
];

const RequestsTable = () => {
    // Sortăm rândurile urgente pentru a fi primele
    const sortedRows = [...rows].sort((a) => (a.urgent === "Da" ? -1 : 1));

    return (
        <Box sx={{ padding: 2, width: "100%", overflow: "hidden" }}>
            <Typography variant="h5" gutterBottom>
                Sesizări
            </Typography>
            <TableContainer
                component={Paper}
                sx={{
                    maxWidth: "100%", // Se ajustează la lățimea containerului
                    overflowX: "auto", // Scroll pe orizontală
                }}
            >
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Camera</TableCell>
                            <TableCell>Departament</TableCell>
                            <TableCell>Sesizare</TableCell>
                            <TableCell>Urgent</TableCell>
                            <TableCell>Data/Ora Plasare</TableCell>
                            <TableCell>Data/Ora Rezolvare</TableCell>
                            <TableCell>Rezolvat de</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRows.map((row) => (
                            <TableRow
                                key={row.camera + row.plasare}
                                sx={{
                                    backgroundColor: row.urgent?.toLowerCase() === "da" ? "#ffcccc" : "inherit",
                                    "&:hover": {
                                        backgroundColor:
                                            row.urgent?.toLowerCase() === "da" ? "#ffaaaa" : "#f5f5f5",
                                        cursor: "pointer",
                                    },
                                }}
                            >
                                <TableCell>{row.camera}</TableCell>
                                <TableCell>{row.departament}</TableCell>
                                <TableCell>{row.sesizare}</TableCell>
                                <TableCell>{row.urgent}</TableCell>
                                <TableCell>{row.plasare}</TableCell>
                                <TableCell>{row.rezolvare}</TableCell>
                                <TableCell>{row.rezolvatDe}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default RequestsTable;
