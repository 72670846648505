import React, { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import {
  Button,
  TextField,
  Box,
  CircularProgress,
  Typography,
  Paper,
  IconButton,
} from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ChatPage = ({ roomNr, sessionId: propSessionId, hotelId: propHotelId }) => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [sessionId, setSessionId] = useState(propSessionId || null);

  const [searchParams] = useSearchParams();
  const user = JSON.parse(localStorage.getItem("user"));
  const currentUserRole = user && [1, 2, 3].includes(Number(user.role)) ? "gazda" : "client";

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const wsRef = useRef(null);

  let roomNumber, hotelId;

  if (currentUserRole === "gazda") {

    // Pentru gazda, folosim hotelId-ul transmis ca prop, dacă este disponibil, altfel fallback la user.id_unitate
    hotelId = propHotelId;

    roomNumber = roomNr;
    if (!roomNumber) {
      return (
        <Typography variant="h6" sx={{ textAlign: "center", marginTop: 2 }}>
          Nu a fost selectată nicio conversație. Vă rugăm să selectați o
          conversație din lista de cereri.
        </Typography>
      );
    }
  } else {
    const qrParam = searchParams.get("qr");
    if (!qrParam || !qrParam.includes("/")) {
      return (
        <Typography variant="h6" sx={{ textAlign: "center", marginTop: 2 }}>
          Parametrul QR este invalid.
        </Typography>
      );
    }
    [roomNumber, hotelId] = qrParam.split("/");
  }

  // Inițierea sesiunii de chat
  useEffect(() => {
    if (!sessionId && currentUserRole === "client") {
      axios
        .post(`${BASE_URL}/startChat`, { hotelId, roomNumber })
        .then((res) => {
          setSessionId(res.data.sessionId);
          sessionStorage.setItem("sessionId", res.data.sessionId);
        })
        .catch((err) => console.error("Eroare la inițierea chatului:", err));
    }
  }, [hotelId, roomNumber, sessionId, BASE_URL, currentUserRole]);

  // Preia mesajele existente din BD
  useEffect(() => {
    if (!sessionId || !hotelId) return;

    // console.log("sesiunea este", sessionId);
    // console.log("📥 Se preiau mesaje pentru hotel:", hotelId);

    axios
      .get(`${BASE_URL}/chat/${roomNumber}/${hotelId}?sessionId=${sessionId}`)
      .then((res) => {
        // console.log("📥 Mesaje preluate cu succes:", res.data);
        // 1) Sortează mesajele în funcție de data/timp (câmpul 'timestamp')
        const sortedMessages = [...res.data].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
        // Mai vechi primele: a < b => -1 => a vine înainte
        // return new Date(a.timestamp) - new Date(b.timestamp);
        // 2) Dacă există un câmp "prioritate" sau "stare" (ex: "necitit"),
        //   poți face un sort mai avansat:
        // const sortedMessages = [...res.data].sort((a, b) => {
        //   if (a.stare === 'prioritară' && b.stare !== 'prioritară') return -1;
        //   if (b.stare === 'prioritară' && a.stare !== 'prioritară') return 1;
        //   return new Date(a.timestamp) - new Date(b.timestamp);
        // });

        setMessages(sortedMessages);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Eroare la încărcarea mesajelor:", err);
        setLoading(false);
      });
  }, [roomNumber, hotelId, sessionId, BASE_URL]);

  // Initializează WebSocket-ul
  useEffect(() => {
    if (!sessionId || wsRef.current) return; // ✅ Evită crearea multiplă a WebSocket-ului

    // console.log("🔌 Initializing WebSocket...");
    const ws = new WebSocket(`wss://${BASE_URL.replace(/^https?:\/\//, "")}/chatWebSocket`);
    wsRef.current = ws;

    ws.onopen = () => {
      // console.log("✅ WebSocket connected");
      setLoading(false);
    };

    ws.onmessage = (event) => {
      if (!wsRef.current) return;
      try {
        const newMessage = JSON.parse(event.data);

        // ✅ Filtrare: acceptă doar mesajele pentru conversația curentă
        if (
          String(newMessage.roomNumber) !== String(roomNumber) ||
          String(newMessage.hotelId) !== String(hotelId) ||
          newMessage.sessionId !== sessionId
        ) {
          // Mesajul nu aparține conversației deschise, deci îl ignorăm
          return;
        }

        // Deduplicare: verificăm dacă mesajul primit (bazat pe o combinație unică: sessionId, timestamp și text) există deja
        setMessages((prev) => {
          const duplicate = prev.find(m =>
            m.sessionId === newMessage.sessionId &&
            m.timestamp === newMessage.timestamp &&
            m.message === newMessage.message
          );
          if (duplicate) {
            return prev;
          }
          // Dacă mesajul nu există deja, îl adăugăm
          return [...prev, newMessage];
        });
      } catch (err) {
        console.error("❌ Eroare la parsarea mesajului WebSocket:", err);
      }
    };

    ws.onerror = (err) => {
      console.error("❌ WebSocket error:", err);
    };

    ws.onclose = () => {
      // console.log("⚠️ WebSocket disconnected, încercăm reconectarea în 3 secunde...");
      wsRef.current = null;

      setTimeout(() => {
        if (!wsRef.current) { // ✅ Evită dublarea conexiunilor!
          console.log("🔄 Reconnecting WebSocket...");
          wsRef.current = new WebSocket(`wss://${BASE_URL.replace(/^https?:\/\//, "")}/chatWebSocket`);

          wsRef.current.onopen = () => {
            console.log("✅ WebSocket reconnected");
          };

          wsRef.current.onmessage = (event) => {
            console.log("📥 Mesaj primit după reconectare:", event.data);
            try {
              const newMessage = JSON.parse(event.data);
              if (String(newMessage.roomNumber) === String(roomNumber) && String(newMessage.hotelId) === String(hotelId)) {
                setMessages((prev) => [...prev, newMessage]);
              }
            } catch (err) {
              console.error("❌ Eroare la parsarea mesajului WebSocket:", err);
            }
          };
        } else {
          console.log("⏳ WebSocket era deja activ, nu reconectăm.");
        }
      }, 3000);
    };

    return () => {
      console.log("🔌 Closing WebSocket...");
      if (wsRef.current) {
        wsRef.current.close();
        wsRef.current = null;
      }
    };
  }, [sessionId, BASE_URL]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = () => {
    if (!sessionId || !wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
      console.error("❌ WebSocket not open, cannot send message.");
      return;
    }

    const messageData = {
      sessionId, // Trimitere sessionId!
      roomNumber,
      hotelId,
      message: input,
      senderRole: currentUserRole,
      timestamp: new Date().toISOString(),
    };

    // console.log("📤 Trimitem mesaj:", messageData);
    wsRef.current.send(JSON.stringify(messageData));

    // setMessages((prev) => [...prev, { senderRole: currentUserRole, message: input }]);
    setInput("");
    scrollToBottom();
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    setShowScrollButton(false);
  };

  const handleScroll = () => {
    if (!messagesContainerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
    setShowScrollButton(scrollHeight - scrollTop > clientHeight + 5);
  };

  return (
    <Box sx={{ padding: 2, maxWidth: "600px", margin: "0 auto" }}>
      {/* <Typography variant="h5" component="h5" sx={{ marginBottom: 2 }}>
        {currentUserRole === "client"
          ? `Chat pentru camera ${roomNumber} - Hotel ${hotelId}`
          : `Chat pentru hotelul ${hotelId}, Camera ${roomNumber}`}
      </Typography> */}
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper
          ref={messagesContainerRef}
          onScroll={handleScroll}
          sx={{ height: "400px", maxHeight: "400px", overflowY: "scroll", padding: 2, marginBottom: 2, position: "relative" }}
        >
          {messages.map((msg, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: msg.senderRole === currentUserRole ? "row-reverse" : "row",
                marginBottom: 1,
              }}
            >
              <Typography
                variant="body1"
                component="div"
                sx={{
                  backgroundColor: msg.senderRole === currentUserRole ? "#e0f7fa" : "#f1f1f1",
                  padding: 1,
                  borderRadius: 2,
                  maxWidth: "80%",
                  wordWrap: "break-word",
                }}
              >
                <strong>{msg.senderRole === currentUserRole ? "Tu" : currentUserRole === "client" ? "Gazda" : "Client"}:</strong>{" "}
                {msg.message}
              </Typography>
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </Paper>
      )}
      {showScrollButton && (
        <IconButton onClick={scrollToBottom} sx={{ position: "fixed", bottom: "104px", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "white", boxShadow: 3, zIndex: 9999, width: "36px", height: "36px", display: "flex", justifyContent: "center", alignItems: "center", "&:hover": { backgroundColor: "#f0f0f0" } }}>
          <ArrowDownwardIcon />
        </IconButton>
      )}
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField variant="outlined" fullWidth value={input} onChange={(e) => setInput(e.target.value)} placeholder="Scrie un mesaj..." />
        <Button variant="contained" color="primary" onClick={handleSendMessage} sx={{ height: "100%" }}>
          Trimite
        </Button>
      </Box>
    </Box>
  );
};

export default ChatPage;
