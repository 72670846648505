import React, { useContext } from "react";
import { UserContext } from "../../App";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import CreateQrCode from "./qrForm";
import GetQrList from "./qrList";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "navigation/index.css";
import "../../App.css";

const QrCode = () => {
    const [submitedChanges, setSubmitedChanges] = useState(0);
    const [hotelList, setHotelList] = useState([]);
    const [errors, setErrors] = useState({});
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();

    const { user } = useContext(UserContext);
    if (!user) {
        navigate("/login");
        return null;
    }

    console.log("user", user.id);
    useEffect(() => {
        Axios.post(`${BASE_URL}/getHotelList`, { userId: user.id })
            .then((response) => {
                if (Array.isArray(response.data) && response.data.length)
                    setHotelList(response.data);
                else
                    setErrors({ response: "Nu există hoteluri adăugate în contul dumneavoastră." });
            })
            .catch((error) => {
                console.error(error);
                setErrors({ response: "A apărut o eroare la procesarea cererii. Vă rugăm să încercați din nou." });
            });
    }, []);

    return (
        <div>
            <TopMenu />
            <Menu />
            <div className="firstContainer">
                <div className="container" data-aos="fade-in">
                    <CreateQrCode setSubmitedChanges1={setSubmitedChanges} />
                    <hr />
                    {errors.response && <div className="alert alert-danger" role="alert">{errors.response}</div>}
                    <GetQrList hotelList={hotelList} submitedChanges2={submitedChanges} />{/*  onChange = {count}*/}
                </div>
            </div>
        </div>
    );
};

export default QrCode;