import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import Axios from "axios";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import AddNewDepartament from "./addDepForm";
import GetDepartamentList from "./departamentsList";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "navigation/index.css";
import "App.css";

const AddDepartament = () => {
    const [count, setCount] = useState(0);
    const [hotelsList, setHotelsList] = useState([]);

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    // const user = JSON.parse(localStorage.getItem("user"));

    const { user } = useContext(UserContext);
    if (!user) {
        // Redirect to login if no user
        window.location.href = "/login";
        return null;
    }

    const handleState = () => {
        setCount(count + 1);
    };

    useEffect(() => {
        Axios.post(`${BASE_URL}/getHotelList`, { userId: user.id }).then((response) => {
            setHotelsList(response.data);
        });
    }, [count]);

    return (
        <div>
            <TopMenu />
            <Menu />
            <div className="firstContainer">
                <div className="container" data-aos="fade-in">
                    <AddNewDepartament hotelsList={hotelsList} setSubmitedChanges={handleState} />
                    <hr />
                    <GetDepartamentList hotelsList={hotelsList} setSubmitedChanges={handleState} count={count} />
                </div>
            </div>
        </div>
    );
};

export default AddDepartament;