import React, { useEffect, useState } from "react";
import Axios from "axios";

import { FormattedMessage } from "react-intl";
import { Table } from "react-bootstrap";

import EditReviewButton from "./modal/edit";
import DeleteReviewButton from "./modal/delete";

const ReviewsList = (props) => {
  const [reviewsList, setReviewsList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({});
  const [submitedChanges, setSubmitedChanges] = useState(0);

  const user = JSON.parse(localStorage.getItem("user"));
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const userData = {
    userId: user.id,
  };

  const getReviewsList = async (userData) => {
    const response = await Axios.post(`${BASE_URL}/getReviewListByIdUser`, userData);

    if (response.data.error) {
      setErrors({ response: response.data.error });
    } else {
      setReviewsList(response.data);
    }
  };

  const handleSubmitedChanges = () => {
    setSubmitedChanges((prev) => prev + 1);
  };

  useEffect(() => {
    (async () => {
      getReviewsList(userData);
    }
    )();
  }, [submitedChanges, props.onChange]);

  return (
    <div>
      <div className="" style={{ paddingTop: 20, paddingBottom: 20 }} >
        <h1 className="text-center">
          <FormattedMessage
            id="reviewsList.title"
            defaultMessage="Lista recenziilor"
          />
        </h1>
      </div>
      <Table responsive style={{ paddingTop: 20, maxWidth: 700, marginLeft: "auto", marginRight: "auto" }}>
        <thead>
          <tr>
            <th>#</th>
            <th><FormattedMessage id="reviewsList.review" defaultMessage="Denumire" /></th>
            <th><FormattedMessage id="reviewsList.url" defaultMessage="Url" /></th>
            <th><FormattedMessage id="reviewsList.hotel" defaultMessage="Hotel" /></th>
            <th><FormattedMessage id="reviewsList.edit" defaultMessage="Editeaza" /></th>
            <th><FormattedMessage id="reviewsList.delete" defaultMessage="Sterge" /></th>
          </tr>
        </thead>
        <tbody>
          {reviewsList.map((review, index) => (
            <tr key={review.idReview}>
              <td>{index + 1}</td>
              <td>{review.denumire}</td>
              <td>{review.url}</td>
              <td>{review.hotel}</td>
              <td>
                <EditReviewButton
                  idReview={review.idReview}
                  denumire={review.denumire}
                  url={review.url}
                  idUnitate={review.idHotel}
                  idUser={review.userId}
                  hotelsList={props.hotelsList}
                  onSubmitedChanges={handleSubmitedChanges}
                />
              </td>
              <td>
                <DeleteReviewButton
                  review={review.denumire}
                  idReview={review.idReview}
                  onChange={setSubmitedChanges}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ReviewsList;