import React, { useEffect, useState, useMemo, useRef } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  ListItemButton,
  Button,
} from "@mui/material";
import ChatPage from "ui/chat/chatPage";
import CloseIcon from "@mui/icons-material/Close";

const ConversationsList = () => {
  const [conversations, setConversations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hotelIds, setHotelIds] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const user = useMemo(() => JSON.parse(localStorage.getItem("user")), []);
  const role = user ? Number(user.role) : null;
  // const isManager = role === 1;
  // const isAngajat = role === 2;

  const wsRef = useRef(null); // ✅ WebSocket Reference

  useEffect(() => {
    if (role === 1) {
      axios
        .post(`${BASE_URL}/getHotelList`, { userId: user.id })
        .then((res) => {
          const hotels = res.data;
          const ids = hotels.map((hotel) => hotel.idUnitate);
          setHotelIds(ids);
        })
        .catch((err) => {
          console.error("Eroare la preluarea listei de hoteluri:", err);
          setHotelIds([]);
        });
    } else {
      setHotelIds([user.id_unitate]);
    }
  }, [role, BASE_URL, user.id, user.id_unitate]);

  // ✅ Inițializare WebSocket
  useEffect(() => {
    console.log("🔌 Initializing WebSocket...");
    wsRef.current = new WebSocket(`wss://${BASE_URL.replace(/^https?:\/\//, "")}/chatWebSocket`);

    wsRef.current.onmessage = (event) => {
      const notification = JSON.parse(event.data);

      if (notification.type === "NEW_MESSAGE") {
        // console.log("📥 Notificare nouă:", notification);
        setConversations((prev) => {
          // Verificăm dacă există deja o conversație pentru combinația roomNumber-hotelId
          const conversationExists = prev.some(conv =>
            conv.roomNumber === notification.roomNumber &&
            conv.hotelId === notification.hotelId
          );

          if (conversationExists) {
            return prev.map((conv) => {
              // Actualizează doar conversația care corespunde combinației roomNumber + hotelId
              if (conv.roomNumber === notification.roomNumber && conv.hotelId === notification.hotelId) {
                return {
                  ...conv,
                  // Setăm notificarea "Mesaj nou!" doar dacă mesajul vine de la client
                  state: notification.senderRole === "client" ? "📩 Mesaj nou!" : "",
                  sessionId: notification.sessionId,
                  timestamp: notification.timestamp,
                };
              }
              return conv;
            });
          } else {
            return [...prev, {
              roomNumber: notification.roomNumber,
              hotelId: notification.hotelId,
              sessionId: notification.sessionId,
              state: notification.senderRole === "client" ? "📩 Mesaj nou!" : "",
              timestamp: notification.timestamp,
            }];
          }
        });
      }
    };

    return () => {
      if (wsRef.current) {
        console.log("🔌 Closing WebSocket...");
        wsRef.current.close();
      }
    };
  }, [BASE_URL]);

  useEffect(() => {
    if (hotelIds.length > 0) {
      let endpoint = "";
      if (hotelIds.length > 1) {
        endpoint = `${BASE_URL}/conversations_multiple?hotelIds=${hotelIds.join(",")}`;
      } else {
        endpoint = `${BASE_URL}/conversations/${hotelIds[0]}`;
      }
      axios
        .get(endpoint)
        .then((res) => {
          setConversations(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error("Eroare la preluarea conversațiilor:", err);
          setLoading(false);
        });
    }
  }, [JSON.stringify(hotelIds), BASE_URL]);

  // ✅ Filtrăm lista astfel încât să avem un singur rând per cameră
  const uniqueRooms = useMemo(() => {
    // Grupăm conversațiile după o cheie unică formată din roomNumber și hotelId
    const grouped = conversations.reduce((acc, conv) => {
      const key = `${conv.roomNumber}-${conv.hotelId}`;
      if (!acc[key] || new Date(conv.timestamp) > new Date(acc[key].timestamp)) {
        acc[key] = conv;
      }
      return acc;
    }, {});

    // Convertim gruparea într-un array
    const result = Object.values(grouped);

    // Sortează rezultatul astfel încât cele cu "Mesaj nou!" să apară primele, apoi în ordine descrescătoare după timestamp
    result.sort((a, b) => {
      if (a.state === "📩 Mesaj nou!" && b.state !== "📩 Mesaj nou!") return -1;
      if (a.state !== "📩 Mesaj nou!" && b.state === "📩 Mesaj nou!") return 1;
      return new Date(b.timestamp) - new Date(a.timestamp);
    });

    return result;
  }, [conversations]);

  const handleConversationClick = (conversation) => {
    setSelectedConversation(conversation);
    setModalOpen(true);

    // console.log("📥 Deschidere chat cu sesiune:", conversation.sessionId);

    // ✅ Resetăm notificarea de "Mesaj nou" la deschiderea chatului
    setConversations((prev) =>
      prev.map((conv) =>
        conv.roomNumber === conversation.roomNumber && conv.hotelId === conversation.hotelId ? { ...conv, state: "", sessionId: conversation.sessionId } : conv
      )
    );
  };

  const handleCloseModal = () => {
    setSelectedConversation(null);
    setModalOpen(false);
  };

  const handleCloseChat = () => {
    sessionStorage.removeItem("sessionId"); // 🔥 Ștergem sesiunea
    setSelectedConversation(null); // 🔥 Închidem chatul
    setModalOpen(false);
  };
  // console.log("selectedConversation", selectedConversation);

  return (
    <Box sx={{ maxWidth: 800, margin: "0 auto", p: 2 }}>
      <Typography variant="h5" component="h5" sx={{ mb: 2 }}>
        Lista conversații
      </Typography>
      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {uniqueRooms.map((conv) => (
            <ListItem key={`${conv.roomNumber}-${conv.sessionId}`} sx={{ borderBottom: "1px solid #ccc" }}>
              <ListItemButton onClick={() => handleConversationClick(conv)}>
                <ListItemText
                  primary={`Camera: ${conv.roomNumber} hotel: ${conv.hotelId}`}
                  secondary={conv.state || "Fără mesaje noi"}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      <Dialog open={modalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>
          {selectedConversation ? `Chat pentru camera: ${selectedConversation.roomNumber} hotel: ${selectedConversation.hotelId}` : ""}
          <Button
            variant="outlined"
            onClick={handleCloseChat}
            sx={{ float: "right", fontSize: "0.8rem", padding: "5px" }}>
            <CloseIcon sx={{ color: "red" }} />
          </Button>
        </DialogTitle>
        <DialogContent>
          {selectedConversation && (
            <ChatPage
              roomNr={selectedConversation.roomNumber}
              sessionId={selectedConversation.sessionId}
              hotelId={selectedConversation.hotelId} // Transmite hotelId-ul specific
            />
          )}
        </DialogContent>

      </Dialog>
    </Box>
  );
};

export default ConversationsList;
