import React from "react";

import { FormattedMessage } from "react-intl";
import { EditUserButton } from "./modal/edit";
import { DeleteUserButton } from "./modal/delete";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";

export const GetUsersList = ({ usersList, hotelsList, departamentsList, handleState }) => {
    console.log("usersListList", usersList);
    // console.log("hotelsList", hotelsList);
    // console.log("departamentsList", departamentsList);
    return (
        <>
            <div className="" style={{ paddingTop: 20 }} >
                <h1 className="text-center">
                    <FormattedMessage
                        id="userList.title"
                        defaultMessage="Lista utilizatorilor mei"
                    />
                </h1>
            </div>
            <div className="table-responsive" style={{ paddingTop: 20 }} >
                {usersList.length != 0 && <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col"><FormattedMessage id="userList.firstName" defaultMessage="Prenume" /></th>
                            <th scope="col"><FormattedMessage id="userList.lastName" defaultMessage="Nume" /></th>
                            <th scope="col"><FormattedMessage id="userList.email" defaultMessage="Utilizator" /></th>
                            <th scope="col"><FormattedMessage id="userList.hotel" defaultMessage="Hotel" /></th>
                            <th scope="col"><FormattedMessage id="userList.departament" defaultMessage="Departament" /></th>
                            <th scope="col"><FormattedMessage id="userList.role" defaultMessage="Rol" /></th>
                            <th scope="col" className="thButtonSpace"><FormattedMessage id="userList.edit" defaultMessage="Edit" /></th>
                            <th scope="col" className="thButtonSpace"><FormattedMessage id="userList.delete" defaultMessage="Sterge" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersList?.map((val, key) => {
                            return (
                                <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>{val.prenume}</td>
                                    <td>{val.nume}</td>
                                    <td>{val.username}</td>
                                    <td>{val.unitName}</td>
                                    <td>{val.depName}</td>
                                    <td>{val.role === 1 ? "Manager" : val.role === 2 ? "Angajat" : ""}</td>
                                    <td className="thButtonSpace">
                                        <EditUserButton
                                            prenume={val.prenume}
                                            nume={val.nume}
                                            user={val.username}
                                            idHotel={val.id_unitate}
                                            hotel={val.unitName}
                                            departament={val.depName}
                                            idDepartament={val.idDepartament}
                                            role={val.role}
                                            userId={val.userId}
                                            hotelsList={hotelsList}
                                            departamentsList={departamentsList}
                                            usersList={usersList}
                                            onChange={handleState}
                                        />
                                    </td>
                                    <td className="thButtonSpace">
                                        {/* {val.role === 1 && */}
                                        <DeleteUserButton
                                            user={val.username}
                                            userId={val.userId}
                                            hotel={val.unitName}
                                            onChange={handleState}
                                        />
                                    </td>
                                </tr>
                            );
                        }
                        )}
                    </tbody>
                </table>
                }
                {usersList.length == 0 && <div className="alert alert-danger" role="alert">
                    <FormattedMessage id="userList.noUsers" defaultMessage="Nu exista utilizatori" />
                </div>}
            </div>
        </>
    );
};