// CardsStats.jsx
import React from "react";
import ComplaintQueriesLogic from "./statistics/ComplaintQueriesLogic";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid2,
} from "@mui/material";
// import Grid2 from "@mui/material/Unstable_Grid2/Grid2"; // Dacă folosești Grid2 de la MUI

const CardsStats = () => {
  return (
    <ComplaintQueriesLogic>
      {({
        totalComplaints,
        activeComplaints,
        inProgressComplaints,
        resolvedComplaints,
        loading,
        error,
      }) => {
        // Dacă se încarcă sau apare o eroare, le poți afișa înainte de a afișa cardurile
        if (loading) {
          return (
            <Box sx={{ padding: 2, width: "100%", paddingTop: 7 }}>
              <Typography variant="h6">Se încarcă...</Typography>
            </Box>
          );
        }
        if (error) {
          return (
            <Box sx={{ padding: 2, width: "100%", paddingTop: 7 }}>
              <Typography variant="h6" color="error">
                Eroare: {error.message}
              </Typography>
            </Box>
          );
        }

        // Construim obiectul de date preluat din componenta de logică
        const data = {
          totalSesizari: totalComplaints,
          sesizariActive: activeComplaints,
          sesizariInCurs: inProgressComplaints,
          sesizariRezolvate: resolvedComplaints,
        };
        console.log("data", data);

        return (
          <Box sx={{ padding: 2, width: "100%", paddingTop: 7 }}>
            {/* <Typography variant="h5" gutterBottom>
            Dashboard Sesizări
          </Typography> */}
            <Grid2 container spacing={2} sx={{ flexWrap: "wrap", justifyContent: "center", flexDirection: "row", width: "100%" }}>

              {/* Card Sesizări Active */}
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <CardHeader title="Active" />
                  <CardContent>
                    <Typography variant="h6" color="textSecondary">
                      {data.sesizariActive}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid2>

              {/* Card Sesizări în Curs */}
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <CardHeader title="În Curs" />
                  <CardContent>
                    <Typography variant="h6" color="textSecondary">
                      {data.sesizariInCurs}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid2>

              {/* Card Sesizări Rezolvate */}
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <CardHeader title="Rezolvate" />
                  <CardContent>
                    <Typography variant="h6" color="textSecondary">
                      {data.sesizariRezolvate}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid2>

              {/* Card Total Sesizări */}
              <Grid2 size={{ xs: 12, sm: 6, md: 3 }}>
                <Card sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <CardHeader title="Total" />
                  <CardContent>
                    <Typography variant="h6" color="textSecondary">
                      {data.totalSesizari}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
          </Box>
        );
      }}
    </ComplaintQueriesLogic>
  );
};

export default CardsStats;
