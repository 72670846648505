import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";

import "bootstrap-icons/font/bootstrap-icons.css";
import "../index.css";

export const OpenImageButton = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDownload = (imageUrl) => {
        const link = document.createElement("a");
        link.href = imageUrl;
        link.download = `${props.hotelName}_camera_${props.qrName}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Button
                onClick={handleShow}
                variant="outline-success"
            >
                <i className="bi bi-qr-code" style={{ color: "#ff5821" }}></i>
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form >
                    <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ textAlign: "center" }}>
                            <Modal.Title><FormattedMessage id="openImage.message1" defaultMessage="Descarci codul QR pentru camera " /> {props.qrName} <br />
                                <FormattedMessage id="openImage.message2" defaultMessage=" al " /> {props.hotelName}?</Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: "center" }}>
                            <img src={props.image} alt="QR Code" />
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            <FormattedMessage id="openImage.no" defaultMessage="Nu" />
                        </Button>
                        <Button variant="success" onClick={() => { handleDownload(props.image); handleClose(); }} type="submit">
                            <FormattedMessage id="openImage.yes" defaultMessage="Da" />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};