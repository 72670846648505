import React, { useEffect, useContext } from "react";
import Axios from "axios";
import { UserContext } from "../../App";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import AddReviewForm from "./reviewsForm";
import ReviewsList from "./reviewsList";
import { FormattedMessage } from "react-intl";

import "./index.css";

const Reviews = () => {
  const [onChange, setOnChange] = React.useState(0);
  const [hotelsList, setHotelsList] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const { user } = useContext(UserContext);
  if (!user) {
    // Redirect to login if no user
    window.location.href = "/login";
    return null;
  }

  const userData = {
    userId: user.id,
  };

  const getHotelsList = async (userData) => {
    const response = await Axios.post(`${BASE_URL}/getHotelList`, userData);

    if (response.data.error) {
      setErrors({ response: response.data.error });
    } else {
      setHotelsList(response.data);
    }
    console.log("errors", errors);
  };

  useEffect(() => {
    (async () => {
      getHotelsList(userData);
    }
    )();
  }, []);

  return (
    <div>
      <TopMenu />
      <Menu />
      <div className="firstRevContainer">
        <div className="revContainer" data-aos="fade-in">
          <h2>
            <FormattedMessage id="rev.mainTitle" defaultMessage="Pagina recenzii" />
          </h2>
          <h6>
            <FormattedMessage id="rev.subtitle" defaultMessage="adauga linkurile paginilor tale de socializare pentru a fi mai usor gasite de clientii tăi" />
          </h6>
        </div>
      </div>
      <div className="containerRevForm">
        <AddReviewForm hotelsList={hotelsList} onChange={setOnChange} />
        <hr />
        <ReviewsList hotelsList={hotelsList} onChange={onChange} />
      </div>
      <Footer />
    </div>
  );
};

export default Reviews;