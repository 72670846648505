import React, { useState } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Form, InputGroup } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const AddWifiForm = ({ hotelsList, handleState }) => {
    const [inputFields, setinputFields] = useState({
        network: "",
        security: "0",
        password: "",
        idUnitate: "",
    });
    const [errors, setErrors] = useState({});
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const validateValues = (inputValues) => {
        console.log("inputValues.security", inputValues.security);
        let errors = {};
        if (!inputValues.network) {
            errors.network = <FormattedMessage id="addWifi.addNameErr" defaultMessage="Adauga numele retelei WiFi" />;
        }
        if (!inputValues.security) {
            errors.security = <FormattedMessage id="addWifi.addSecurityErr" defaultMessage="Adauga tipul de securitate" />;
        }
        if (!inputValues.password && inputValues.security !== "OPEN") {
            errors.password = <FormattedMessage id="addWifi.addPasswordErr" defaultMessage="Adauga parola" />;
        }
        if (inputValues.idUnitate === "0" || !inputValues.idUnitate) {
            errors.idUnitate = <FormattedMessage id="addWifi.selectHotel" defaultMessage="Alege hotelul" />;
        }
        return errors; // returneaza un obiect gol daca nu sunt erori
    };

    const resetForm = () => {
        setinputFields({
            network: "",
            security: "",
            password: "",
            idUnitate: "",
        });
        setErrors({});
    };

    const handleChange = (event) => {
        setinputFields({
            ...inputFields,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validateValues(inputFields);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            Axios.post(`${BASE_URL}/addWifiNetwork`, {
                network: inputFields.network,
                security: inputFields.security,
                password: inputFields.password,
                idUnitate: inputFields.idUnitate,
            }).then((response) => {
                console.log("response", response);
                handleState();
                resetForm();
            }).catch((error) => {
                setErrors({ response: error });
            });
        }
    };

    return (
        <div className=" d-flex align-items-center justify-content-center">
            <div className="">
                <Form onSubmit={handleSubmit}>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addWifi.network" defaultMessage="Retea Wi-Fi" /></InputGroup.Text>
                        <Form.Control
                            type="text"
                            name="network"
                            value={inputFields.network}
                            onChange={handleChange}
                            className="inputLabel"
                        />
                    </InputGroup>
                    {errors.network && <p className="error">{errors.network}</p>}
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addWifi.security" defaultMessage="Securitate" /></InputGroup.Text>
                        <Form.Select
                            name="security"
                            value={inputFields.security}
                            onChange={handleChange}
                            className="inputLabel"
                        >
                            <option value="0"><FormattedMessage id="addWifi.securityType" defaultMessage="Tipul de securitate" /></option>
                            <option value="OPEN">OPEN</option>
                            <option value="WEP">WEP</option>
                            <option value="WPA">WPA</option>
                            <option value="WPA2">WPA2</option>
                            <option value="WPA3">WPA3</option>
                        </Form.Select>
                    </InputGroup>
                    {errors.security && <p className="error">{errors.security}</p>}
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addWifi.password" defaultMessage="Parola" /></InputGroup.Text>
                        <Form.Control
                            type="text"
                            name="password"
                            value={inputFields.password}
                            onChange={handleChange}
                            className="inputLabel"
                        />
                    </InputGroup>
                    {errors.password && <p className="error">{errors.password}</p>}
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addWifi.idUnitate" defaultMessage="Hotelul" /></InputGroup.Text>
                        <Form.Select
                            name="idUnitate"
                            value={inputFields.idUnitate}
                            onChange={handleChange}
                        >
                            <option value="0"><FormattedMessage id="addWifi.selectHotel" defaultMessage="Alege hotelul" /></option>
                            {hotelsList?.map((hotel, key) => (
                                <option key={key} value={hotel.idUnitate}>{hotel.denumire}</option>
                            ))}
                        </Form.Select>
                    </InputGroup>
                    {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
                    <button
                        type="submit"
                        style={{ marginTop: 10 }}
                        className="universalButton buttonUniv btn-rounded waves-effect waves-light"
                    >
                        <FormattedMessage id="addWifi.save" defaultMessage="Salveaza" />
                    </button>
                </Form>
            </div>
        </div>
    );
};

export default AddWifiForm;