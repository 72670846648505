import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Button, Grid2, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChatPage from "../chat/chatPage";

import HotelInfo from "./hotelInfo";
import "./index.css";

const HotelServicesMenu = ({ restaurantsList, wifiData, payedOptions, errors, hotelData, roomId, departments, countyId, cityId }) => {
  // console.log("restaurantsList", restaurantsList);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isMessageFormOpen, setIsMessageFormOpen] = useState(false);
  // const [message, setMessage] = useState("");
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleToggleMessageForm = () => {
    // Dacă chatul e deschis, închide-l și șterge sesiunea
    if (isChatOpen) {
      sessionStorage.removeItem("sessionId");
    }
    setIsChatOpen(prev => !prev);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  const handleHomeButton = () => {
    setIsOptionsOpen(isOptionsOpen ? !isOptionsOpen : isOptionsOpen);
    setIsMessageFormOpen(isMessageFormOpen ? !isMessageFormOpen : isMessageFormOpen);
  };

  const handleToggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  // const handleToggleMessageForm = () => {
  //   setIsMessageFormOpen(!isMessageFormOpen);
  // };

  // const handleSendMessage = () => {
  //   // Logica pentru trimiterea mesajului (poți implementa aici un API call sau altceva)
  //   // console.log("Mesaj trimis:", message);
  //   setMessage(""); // Resetează formularul
  //   setIsMessageFormOpen(false); // Închide formularul
  // };

  const activeOptions = payedOptions.filter(option => option.esteActiv === 1);
  console.log("activeOptions", activeOptions);

  return (
    <div className="hotel-services-container">
      <HotelInfo
        errors={errors}
        hotelData={hotelData}
        roomId={roomId}
        departments={departments}
        countyId={countyId}
        cityId={cityId}
      />
      {/* Meniul de servicii */}
      {isOptionsOpen && (
        <div className="hotel-services-menu">
          <h3>Servicii Hotel</h3>
          <div className="responsive-table services-list">
            {activeOptions.map((option) => (
              <Accordion
                key={option.id}
                expanded={expandedAccordion === option.optiune.toLowerCase()}
                onChange={handleAccordionChange(option.optiune.toLowerCase())}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  {option.optiune}
                </AccordionSummary>
                <AccordionDetails>
                  {option.optiune === "Wifi" && (
                    <Table sx={{ maxWidth: 650 }} size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>Rețea</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>SSID</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Parolă</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Securitate</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {wifiData.map((wifi, index) => (
                          <TableRow key={wifi.id}>
                            <TableCell component="th" scope="row">
                              Rețea {index + 1}
                            </TableCell>
                            <TableCell>{wifi.network}</TableCell>
                            <TableCell>{wifi.password}</TableCell>
                            <TableCell>{wifi.security}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </AccordionDetails>

                <AccordionDetails>
                  {option.optiune == "CheckInOut" && (
                    <Table sx={{ maxWidth: 650 }} size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>Ora de check-in</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Ora de check-out</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>{hotelData[0].checkIn}</TableCell>
                          <TableCell>{hotelData[0].checkOut}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </AccordionDetails>

                <AccordionDetails>
                  {option.optiune === "Restaurant" && (
                    <Table sx={{ maxWidth: 600 }} size="medium">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold", width: "100px" }}>Nume restaurant</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Mic dejun</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Prânz</TableCell>
                          <TableCell sx={{ fontWeight: "bold" }}>Cină</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {restaurantsList.map((restaurant) => (
                          <TableRow key={restaurant.id}>
                            <TableCell>{restaurant.restaurantName}</TableCell>
                            <TableCell>{restaurant.breakfast}</TableCell>
                            <TableCell>{restaurant.lunch}</TableCell>
                            <TableCell>{restaurant.dinner}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </AccordionDetails>

              </Accordion>
            ))}
          </div>
        </div>
      )
      }

      {/* Formular pentru trimiterea mesajului */}
      {
        isChatOpen && (
          <div className="message-form">
            <h3>Chat</h3>
            <Grid2 item xs={12} sm={6} lg={4}>
              <ChatPage />
            </Grid2>
          </div>
        )
      }

      {/* Bara de meniu fixă în partea de jos */}
      <div className="bottom-bar">
        <Button variant="contained" color="primary" style={{ width: "33%" }} onClick={handleHomeButton}>
          Acasă
        </Button>
        <Button variant="contained" color="secondary" style={{ width: "33%" }} onClick={handleToggleOptions}>
          Servicii
        </Button>
        <Button
          variant="contained"
          color={isChatOpen ? "error" : "success"} // Roșu când este deschis (închide chat), verde când este închis (deschide chat)
          style={{ width: "33%" }}
          onClick={handleToggleMessageForm}
        >
          {isChatOpen ? "Închide" : "Chat"}
        </Button>

      </div>
    </div >
  );
};

export default HotelServicesMenu;
