import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Box, Drawer } from "@mui/material";
import Grid from "@mui/material/Grid2";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import RequestsTable from "./requestsTable";
import CardsStats from "./cardsStats";
// import ChatPage from "../../ui/chat/chatPage";
import ConversationsList from "./conversationsList";

import "./index.css";
import "../../App.css";

const Dashboard = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Starea pentru meniu pe mobil

    return (
        <Box sx={{ flexGrow: 1 }}>
            <TopMenu />
            <Menu />
            {/* Drawer lateral pentru meniu pe mobil */}
            <Drawer anchor="left" open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
                <Menu />
            </Drawer>
            <Grid container spacing={3}>

                <Grid item xs={12} sm={6} md={8} lg={8}>
                    <h2>
                        <FormattedMessage id="dashboard.title" defaultMessage="Panou de control" />
                    </h2>
                    <h6>
                        <FormattedMessage id="dashboard.subtitle" defaultMessage="Tot ce îți dorești să știi" />
                    </h6>
                    <CardsStats />
                    <RequestsTable />
                </Grid>

                {/* Chat-ul */}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                    CHATT
                    {/* <ChatPage /> */}
                    <ConversationsList />

                </Grid>
            </Grid>

            <Footer />
        </Box>
    );
};

export default Dashboard;
