const createTimeFormat = (timeString) => {
  const [hours, minutes] = timeString.split(":"); // Desparte ora și minutul
  const date = new Date(); // Obține data curentă
  date.setHours(hours, minutes, 0); // Setează ora și minutul, cu secunde la 0

  const hoursFormatted = String(date.getHours()).padStart(2, "0"); // Formatează ora la 2 caractere
  const minutesFormatted = String(date.getMinutes()).padStart(2, "0"); // Formatează minutul la 2 caractere

  return `${hoursFormatted}:${minutesFormatted}`; // Returnează în formatul `HH:mm`
};

export default createTimeFormat;
