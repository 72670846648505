import React, { useState } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Form, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ro from "date-fns/locale/ro";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

import "./index.css";

// Înregistrează limba română
registerLocale("ro", ro);

const EventForm = (props) => {
    const [inputFields, setInputFields] = useState({
        eventName: "",
        eventDescriber: "",
        eventDateTime: new Date(), // Setează o dată inițială pentru eventDateTime
        idUnitate: "",
    });
    const [errors, setErrors] = useState({});
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    // Funcția de validare
    const validateValues = (inputValues) => {
        let errors = {};
        if (!inputValues.eventName) {
            errors.eventName = <FormattedMessage id="events.eventNameErr" defaultMessage="Adauga denumirea evenimentului" />;
        }
        if (!inputValues.eventDescriber) {
            errors.eventDescriber = <FormattedMessage id="events.describerErr" defaultMessage="Adauga descrierea evenimentului" />;
        }
        if (!inputValues.eventDateTime) {
            errors.eventDateTime = <FormattedMessage id="events.dateTimeErr" defaultMessage="Alege data și ora evenimentului" />;
        }
        if (inputValues.idUnitate === "0" || !inputValues.idUnitate) {
            errors.idUnitate = <FormattedMessage id="events.selectHotel" defaultMessage="Alege hotelul" />;
        }
        return errors;
    };

    const handleChange = (event) => {
        setInputFields({
            ...inputFields,
            [event.target.name]: event.target.value,
        });
    };

    const handleDateTimeChange = (date) => {
        setInputFields({
            ...inputFields,
            eventDateTime: date,
        });
    };

    // Funcția de conversie în UTC
    const convertToUTC = (date) => {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const errors = validateValues(inputFields);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            const utcDateTime = convertToUTC(inputFields.eventDateTime).toISOString(); // Convertire în UTC string
            // console.log({ UTC: utcDateTime });

            Axios.post(`${BASE_URL}/addNewEvent`, {
                eventName: inputFields.eventName,
                eventDescriber: inputFields.eventDescriber,
                eventDateTime: utcDateTime,
                idUnitate: inputFields.idUnitate,
            })
                .then((response) => {
                    props.onChange((counter) => counter + 1);
                    console.log("response", response);

                    setInputFields({
                        eventName: "",
                        eventDescriber: "",
                        eventDateTime: new Date(), // Setează o dată inițială pentru eventDateTime
                        idUnitate: "",
                    });
                })
                .catch((error) => {
                    console.error("Error occurred during form submission:", error);
                });
        }
    };

    // Funcția pentru filtrarea timpului trecut
    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };

    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="">
                <Form onSubmit={handleSubmit}>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="events.eventName" defaultMessage="Denumire" />
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            name="eventName"
                            value={inputFields.eventName}
                            onChange={handleChange}
                            className="inputLabel"
                        />
                    </InputGroup>
                    {errors.eventName && <p className="error">{errors.eventName}</p>}
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="events.describer" defaultMessage="Descriere" />
                        </InputGroup.Text>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            name="eventDescriber"
                            value={inputFields.eventDescriber}
                            onChange={handleChange}
                            className="textAreaStyled"
                        />
                    </InputGroup>
                    {errors.eventDescriber && <p className="error">{errors.eventDescriber}</p>}
                    <InputGroup
                        style={{
                            marginTop: 10,
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "stretch",
                            width: "100%",
                        }}
                    >
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="events.dateTime" defaultMessage="Data" />
                        </InputGroup.Text>
                        <DatePicker
                            selected={inputFields.eventDateTime}
                            onChange={handleDateTimeChange}
                            name="eventDateTime"
                            showTimeSelect
                            dateFormat="dd MMMM yyyy HH:mm"
                            timeIntervals={15}
                            locale="ro"
                            filterTime={filterPassedTime}
                            className="form-control"
                        />
                    </InputGroup>
                    {errors.eventDateTime && <p className="error">{errors.eventDateTime}</p>}
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="events.hotel" defaultMessage="Hotelul" />
                        </InputGroup.Text>

                        <Form.Select name="idUnitate" value={inputFields.idUnitate} onChange={handleChange}>
                            <option value="0">
                                <FormattedMessage id="events.selectHotel" defaultMessage="Alege hotelul" />
                            </option>
                            {props.hotelsList.map((hotel, key) => (
                                <option key={key} value={hotel.idUnitate}>
                                    {hotel.denumire}
                                </option>
                            ))}
                        </Form.Select>

                    </InputGroup>
                    {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
                    <button
                        type="submit"
                        style={{ marginTop: 10 }}
                        className="universalButton buttonUniv btn-rounded waves-effect waves-light"
                    >
                        <FormattedMessage id="checkInOut.save" defaultMessage="Salveaza" />
                    </button>
                </Form>
            </div>
        </div>
    );
};

export default EventForm;
